import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import classNames from "classnames";
import dayjs from "dayjs";
import { FC, useState } from "react";
import updateLocale from "dayjs/plugin/updateLocale";
import "../../../../pages/PointEventsPage/styles.scss";

type Props = {
    value: string | null;
    onChangeFilters: (value: string | null) => void;
    placeholder?: string;
    blockClear?: boolean;
    className?: string;
};

const dateRegExp = /^\d{2}\.\d{1,2}\.\d{4}$/;

dayjs.locale("ru-RU");
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
    weekStart: 1,
});

const FiltersDatePicker: FC<Props> = (props) => {
    const { value, onChangeFilters, placeholder, className, blockClear } =
        props;

    function update(val: dayjs.Dayjs | null) {
        if (val === null) {
            onChangeFilters(val);
            setValueDate(val);
            return;
        }
        let d: number | string = val.get("date");
        d = d < 10 ? `0${d}` : d;
        let m: number | string = val.get("month") + 1;
        m = m < 10 ? `0${m}` : m;
        const y = val.get("year");
        setValueDate(val);

        onChangeFilters([d, m, y].join("."));
    }

    const [valueDate, setValueDate] = useState<dayjs.Dayjs | null>(() => {
        if (typeof value === "string" && dateRegExp.test(value)) {
            const [date, month, year] = value.split(".");

            return dayjs()
                .set("year", +year)
                .set("month", +month)
                .set("date", +date)
                .subtract(1, "month");
        }
        return null;
    });

    return (
        <ConfigProvider>
            <DatePicker
                locale={locale}
                placeholder={placeholder ?? "Выберите"}
                value={valueDate ? dayjs(valueDate) : null}
                format={"DD.MM.YYYY"}
                onChange={update}
                allowClear={blockClear !== true}
                onReset={() => onChangeFilters(null)}
                className={classNames("date-picker", "lukoil", className)}
            />
        </ConfigProvider>
    );
};

export default FiltersDatePicker;
