import { Flex, Typography } from "antd";
import { FC } from "react";

type Props = {
    title: string;
    value: string;
};
const PointItem: FC<Props> = (props) => {
    const { title, value } = props;

    return (
        <Flex align="center" justify="space-between" gap={10}>
            <Typography.Text strong>{title}</Typography.Text>

            <Typography.Text>{value}</Typography.Text>
        </Flex>
    );
};

export default PointItem;
