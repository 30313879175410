import { Flex } from "antd";
import { FC } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import BottomTableCell from "./BottomTableCell";

type Props = {
    item: { key: string; values: string[] | string };
};
const BottomTableRow: FC<Props> = ({ item }) => {
    const adaptedValue = Array.isArray(item.values)
        ? item.values
        : [item.values];

    return (
        <Flex className={styles.row}>
            <div
                className={classNames(styles.cell, styles["bottom-table__key"])}
            >
                {item.key}
            </div>{" "}
            <Flex align="stratch" style={{ width: "100%" }}>
                {adaptedValue.map((el, index) => (
                    <BottomTableCell
                        key={index}
                        style={{
                            flex: `0 0 calc(100%/${adaptedValue.length})`,
                        }}
                        fieldKey={item.key}
                        value={el}
                    />
                ))}
            </Flex>
        </Flex>
    );
};

export default BottomTableRow;
