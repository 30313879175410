import React, { useMemo } from "react";
import { Form } from "antd";
import { EditableRowContext } from "entities/EditableTable/context/EditableRowContext";
import { Data, EditableColumnTypes } from "entities/EditableTable/Types";

interface EditableRowProps {
    index: number;
    data: Data;
    columns: EditableColumnTypes;
}

const EditableRow: React.FC<
    EditableRowProps & React.HTMLAttributes<HTMLTableRowElement>
> = ({ index, data, columns, ...props }) => {
    const [form] = Form.useForm();

    const initital = useMemo(() => {
        if (data.Id !== -1) return data;
        return columns.reduce(
            (acc, col) => ({
                ...acc,
                [col.dataIndex]: col.defaultValue
                    ? col.defaultValue
                    : col.options?.length
                    ? col.options[0].value
                    : null,
            }),
            data
        );
    }, [data]);

    return (
        <Form
            form={form}
            component={false}
            initialValues={initital}
            validateTrigger="onSubmit"
        >
            <EditableRowContext.Provider value={{ form }}>
                <tr {...props} />
            </EditableRowContext.Provider>
        </Form>
    );
};

export default EditableRow;
