import "./styles.scss";
import LogoSvg from "../../components/Logo/logo";
import RedFrame from "./RedFrame";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchLoginAppBasic } from "../../services/login/loginSlice";
import RedButton from "shared/components/RedButton";
import { ConfigProvider, Form, Input } from "antd";
import { callError } from "shared/helpers";

const LoginPage = () => {
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector((state) => state.Login);

    const onLogin = () => {
        form.validateFields()
            .then((data) => {
                dispatch(
                    fetchLoginAppBasic({
                        login: data.login,
                        password: data.password,
                    })
                );
            })
            .catch(() => {
                callError("Неправильное заполнение формы");
            });
    };

    const [form] = Form.useForm();

    return (
        <div className="flex login-container">
            <div className="half-container">
                <LogoSvg />
                <div className="form-container">
                    <h1>Вход в систему</h1>
                    <p>
                        Введите логин и пароль для осуществления входа в систему
                        enrsoft
                    </p>
                    {/* <form action="https://dev.enrsoft.ru/login.html" method="post"> */}
                    <ConfigProvider
                        theme={{
                            components: {
                                Form: {
                                    itemMarginBottom: 0,
                                },
                                Input: {
                                    inputFontSize: 16,
                                    controlHeight: 45,
                                },
                            },
                        }}
                    >
                        <Form
                            wrapperCol={{ span: 48 }}
                            form={form}
                            onFinish={onLogin}
                            autoComplete=""
                        >
                            <Form.Item
                                required
                                name="login"
                                rules={[
                                    {
                                        required: true,
                                        message: "Обязательное поле",
                                    },
                                ]}
                            >
                                <Input placeholder="Логин" />
                            </Form.Item>

                            <Form.Item
                                required
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Обязательное поле",
                                    },
                                ]}
                            >
                                <Input placeholder="Пароль" type="password" />
                            </Form.Item>

                            <RedButton
                                style={{ width: "100%" }}
                                disabled={isLoading}
                                type="submit"
                            >
                                Войти
                            </RedButton>
                        </Form>
                    </ConfigProvider>
                </div>
            </div>
            <div className="half-container red">
                <RedFrame />
            </div>
        </div>
    );
};

export default LoginPage;
