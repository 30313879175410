import classNames from "classnames";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
} from "react";
import styles from "./styles.module.css";
import { useAppSelector } from "app/hooks";

type Props = {
    style?: React.CSSProperties;
    dataIndex: string;
    className?: string;
    value?: string;
    isNotAllowedToEdit?: boolean;
    onDoubleClick?: () => void;
    onEnterClick?: () => void;
    onChange: (val: string) => void;
};
const EditableElement = forwardRef((props: Props, ref) => {
    const {
        style,
        dataIndex,
        className,
        value,
        onDoubleClick,
        isNotAllowedToEdit,
        onEnterClick,
        onChange,
    } = props;

    function setEditCell() {
        openStatus.current = true;
        if (inputRef.current !== null) {
            const input = inputRef.current as HTMLInputElement;
            input.focus();
        }
    }

    const { fontSize } = useAppSelector((state) => state.Excel);

    const inputRef = useRef(null);
    const openStatus = useRef(false);

    useEffect(() => {
        if (openStatus.current) {
            const input = inputRef.current;
            if (input !== null) {
                (input as HTMLInputElement).focus();
            }
        }
    }, [openStatus.current]);

    useImperativeHandle(
        ref,
        () => {
            return {
                setEditCell,
                close: () => (openStatus.current = false),
                openStatus: openStatus.current,
            };
        },
        []
    );

    return (
        <div
            style={style}
            data-id={dataIndex}
            className={classNames(styles["editable-element"], className, {
                [styles.uneditable]: isNotAllowedToEdit,
            })}
            onDoubleClick={() => {
                if (!isNotAllowedToEdit) {
                    setEditCell();
                    onDoubleClick?.();
                }
            }}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    openStatus.current = false;
                    onEnterClick?.();
                }
            }}
        >
            <>
                {openStatus.current && (
                    <input
                        value={value}
                        style={{ fontSize }}
                        ref={inputRef}
                        onChange={(e) => onChange(e.target.value)}
                        className={classNames(
                            styles["editable-element__input"],
                            {
                                [styles.hidden]: !openStatus.current,
                            }
                        )}
                    />
                )}
                <span
                    className={classNames(styles["editable-elemnt__value"], {
                        [styles.none]: openStatus.current,
                    })}
                >
                    {value}
                </span>
            </>
        </div>
    );
});

export default EditableElement;
