import { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useClickOutside } from "../useClickOutside";
import { useTableBodyContext } from "../context";
import classNames from "classnames";
import { ITableRow } from "entities/ExcelTable/types";
import { useActions, useAppSelector } from "app/hooks";

type Props = {
    order?: number;
    setOpen: () => void;
    isOpen: boolean;
    dataIndex: string;
    lineConfig?: number[];
    row: ITableRow;
    isPicked: boolean;
};
const ExcelNameCell: FC<Props> = (props) => {
    const { order, setOpen, isOpen, dataIndex, lineConfig, row, isPicked } =
        props;

    const value = row.Cells?.[0]?.CellData?.Value ?? "";
    const children = row.Children;

    const [isOpenInput, setIsOpenInput] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState(String(value ?? ""));
    const ref = useRef(null);

    useClickOutside(ref, () => setIsOpenInput(false));

    useEffect(() => {
        setInputValue(String(row.Cells?.[0]?.CellData?.Value ?? ""));
    }, [row.Cells?.[0]?.CellData?.Value]);

    const { pickedCells } = useTableBodyContext();
    const { togglePointedRows } = useActions();

    const isActive = useMemo<boolean>(
        () => pickedCells.pickedCells.includes(dataIndex),
        [pickedCells.pickedCells]
    );

    const inputRef = useRef(null);

    const {
        fontSize,
        colors: { name: nameColumnColor },
    } = useAppSelector((state) => state.Excel);
    const translated = (order ?? 0) * 10;

    return (
        <td
            aria-colindex={0}
            data-id={dataIndex}
            ref={ref}
            onDoubleClick={() => {
                setIsOpenInput(true);
                if (inputRef.current !== null) {
                    const input = inputRef.current as HTMLInputElement;
                    input.focus();
                }
            }}
            style={{ backgroundColor: isPicked ? "orange" : nameColumnColor }}
            className={classNames(styles["excel-name-column"], {
                [styles.active]: isActive,
            })}
        >
            <button
                onClick={() => togglePointedRows(row.Id)}
                className={classNames(styles["excel__trigger"], "animated", {
                    [styles.pointed]: isPicked,
                })}
            />
            {lineConfig?.map((el) => (
                <div
                    key={el}
                    className={classNames(styles["excel__line-depth"], {
                        [styles["excel__line-start"]]: el === (order ?? 0),
                    })}
                    style={{ left: el * 5 + 5 + "px" }}
                />
            ))}
            {children?.length && (
                <button
                    onClick={setOpen}
                    className={styles["excel-expanded-button"]}
                >
                    {isOpen ? "-" : "+"}
                </button>
            )}

            <input
                style={{ fontSize }}
                ref={inputRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className={classNames(styles.input, {
                    [styles.hidden]: !isOpenInput,
                })}
            />

            <span
                style={{
                    transform: `translateX(${translated}px)`,
                    width: `calc(100% - ${translated}px)`,
                }}
                className={classNames(styles["excel-translated-span"], {
                    [styles.none]: isOpenInput,
                })}
            >
                {value}
            </span>
        </td>
    );
};

export default ExcelNameCell;
