import { AddButton } from "components/UI";
import { Select } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { GuideTemplateEnergy } from "entities/ISGuide/components";
import { Modal } from "shared/components";
import {
    isResourceMode,
    Template,
    TemplateResponse,
} from "entities/ISGuide/types";
import { useFetch } from "hooks";
import {
    getTemplatePositions,
    setTemplatePosition,
} from "entities/ISGuide/api";
import { SerializedObject } from "shared/SerializedObject";
import { callSuccess } from "shared/helpers";
import { availableYears } from "shared/constants/common";
import { useCreatingForm } from "entities/ISGuide/helpers";

type Props = {
    currentValue: Template | null | undefined;
    onCloseModal: (data?: TemplateResponse) => void;
};

type Filter = {
    year: string;
    picked: number[];
};

const parsedYears = availableYears.map((el) => ({
    label: el.key,
    value: el.value,
}));

const TemplateModal: FC<Props> = (props) => {
    const { currentValue, onCloseModal } = props;

    const [filters, setFilters] = useState<SerializedObject<Filter>>(
        new SerializedObject({
            year: "",
            picked: [],
        })
    );

    useEffect(() => {
        if (
            currentValue?.EffectiveDate &&
            currentValue.EffectiveDate !== filters.obj.year
        ) {
            setFilters((prev) => {
                return prev.clone({
                    ...prev.obj,
                    year: currentValue.EffectiveDate as string,
                });
            });
        }
    }, [currentValue]);

    async function onCreatingTemplate() {
        const response = await setTemplatePosition(
            filters.obj.picked,
            snapshot,
            filters.obj.year
        );
        callSuccess("Успешно");
        onCloseModal(response);
    }

    function onChangeFilter(key: keyof Filter, val: Filter[keyof Filter]) {
        setFilters((prev) => {
            return prev.clone({ ...prev.obj, [key]: val });
        });
    }

    function onChangeValue(name: string, key: string, value: string) {
        setSnapshot((prev) => {
            const obj = { ...prev };
            if (!isResourceMode(value)) return obj;

            if (obj[name]) {
                obj[name][key] = value;
            } else {
                obj[name] = { [key]: value };
            }
            return obj;
        });
    }

    const [snapshot, setSnapshot] = useState<Template["Map"]>({});

    useEffect(() => {
        setSnapshot(() => {
            if (!currentValue) return {};
            return structuredClone(currentValue.Map);
        });
        if (currentValue !== null) {
            setFilters((prev) => {
                return prev.clone(
                    currentValue === undefined
                        ? {
                              year: "",
                              picked: [],
                          }
                        : {
                              ...prev.obj,
                              picked: [currentValue.PositionId],
                          }
                );
            });
        }
    }, [currentValue]);

    const { data: objects } = useFetch(getTemplatePositions);

    const parsedObjects = useMemo(() => {
        if (!objects?.length) return [];
        return objects
            .filter((el) => !!el.Position)
            .map((obj) => ({
                value: obj.Id,
                label: obj.Position,
            }));
    }, [objects]);

    const creatingForm = useCreatingForm();

    return (
        <Modal
            title="Настройка"
            showed={currentValue !== undefined}
            closedOnAway
            closeShowed={() => onCloseModal()}
        >
            <Select
                value={filters.obj.year}
                style={{ height: 50 }}
                placeholder="Год"
                title="Год"
                onChange={(val) => onChangeFilter("year", val)}
                options={parsedYears}
            />
            {!currentValue && (
                <Select
                    mode="multiple"
                    onChange={(val) => onChangeFilter("picked", val)}
                    value={filters.obj.picked}
                    optionFilterProp="label"
                    options={parsedObjects}
                    style={{ height: 50 }}
                    placeholder="Добавить установку"
                />
            )}
            {creatingForm.map((el, id) => (
                <GuideTemplateEnergy
                    key={id}
                    element={el}
                    value={snapshot}
                    changeValue={onChangeValue}
                />
            ))}
            <AddButton
                onClick={onCreatingTemplate}
                disabled={!filters.isSerialized}
            >
                Сохранить
            </AddButton>
        </Modal>
    );
};

export default TemplateModal;
