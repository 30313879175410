import { ConfigProvider, Flex, Table } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import ArchiveFilters from "../ArchiveFilters";
import { getColumnsApiDictionary } from "entities/ExcelTable/helpers/archive.constants";
import { ColumnTypes } from "entities/EditableTable/Types";
import { IRadioFilterItem } from "shared/types/is-energy";
import { TableTabItem, TabsParams } from "entities/ExcelTable/types";
import { useEffectSkipFirstRender, useFetch } from "hooks";
import { BigLoader } from "components/UI";
import { useActions, useAppSelector } from "app/hooks";
import { useArchiveToReport } from "entities/ExcelTable/hooks/useArchiveToReport";
import { useLocation } from "react-router-dom";

type Props = {
    staticItems?:
        | IRadioFilterItem[]
        | ((filter?: { [key: string]: string }) => IRadioFilterItem[]);
    type: TabsParams;
    isShowed: boolean;
    closeShowed: () => void;
    tabs: TableTabItem[];
    createTab: (item: TableTabItem) => void;
};

const defaultValue = {
    Period: String(new Date().getFullYear()) + "-01-01T00:00:00",
};

const ArchiveContent: FC<Props> = (props) => {
    const { type, isShowed, closeShowed, staticItems, createTab, tabs } = props;
    const { resources, guideCreatingForm } = useAppSelector(
        (state) => state.Excel
    );
    const { changeActiveIndex } = useActions();

    const config = useMemo(() => {
        return getColumnsApiDictionary(resources)[type];
    }, [type, resources]);

    function onChangeFilter(key: string, value: string) {
        setFilter((prev) => ({ ...prev, [key]: value }));
    }

    const [filter, setFilter] = useState<{ [key: string]: string }>(
        defaultValue
    );

    const location = useLocation();

    useEffect(() => {
        if (
            guideCreatingForm.form.length &&
            location.pathname.includes("balance")
        ) {
            const type = location.pathname.split("/").at(-1) ?? "";
            const actual = guideCreatingForm.form[0].items.find((item) =>
                item.value.toLowerCase().includes(type)
            );
            if (actual) {
                setFilter({ ...defaultValue, energy: actual.value });
                return;
            }
            setFilter(defaultValue);
        } else {
            setFilter(defaultValue);
        }
    }, [guideCreatingForm, location.pathname]);

    const { data: sources, trigger } = useFetch(config.api, filter);

    useEffectSkipFirstRender(() => {
        trigger();
    }, tabs);

    const actualItems = useMemo<IRadioFilterItem[]>(() => {
        const subItems = staticItems
            ? Array.isArray(staticItems)
                ? staticItems
                : staticItems(filter)
            : [];
        return [...guideCreatingForm.getActualForm(filter), ...subItems];
    }, [filter, guideCreatingForm]);

    useEffect(() => {
        if (
            !isShowed &&
            JSON.stringify(filter) !== JSON.stringify(defaultValue)
        ) {
            setFilter(defaultValue);
        }
    }, [isShowed]);

    const generateReportName = useArchiveToReport();

    if (!sources) return <BigLoader />;
    return (
        <Flex vertical gap={25}>
            {isShowed && (
                <ArchiveFilters
                    items={actualItems}
                    value={filter}
                    changeValue={onChangeFilter}
                />
            )}

            <ConfigProvider renderEmpty={() => <></>}>
                <Table
                    dataSource={sources}
                    onRow={(record) => {
                        return {
                            onDoubleClick: () => {
                                if ("Id" in record) {
                                    const name = generateReportName(record)
                                    changeActiveIndex({
                                        rate: record.Id,
                                        table: record.ReportTableId,
                                        name: name.fullName
                                    });
                                    closeShowed();
                                    createTab(name);
                                }
                            },
                        };
                    }}
                    pagination={false}
                    bordered
                    columns={config.columns as ColumnTypes}
                />
            </ConfigProvider>
        </Flex>
    );
};

export default ArchiveContent;
