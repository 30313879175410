import React, { FC, useMemo } from "react";
import styles from "./styles.module.css";
import { ChildrenCell } from "entities/ISGuide/types";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import classNames from "classnames";

const layout = ["Год", "Энергоресурсы", "Действия"];

type Props = {
    closedStatus: boolean;
    replaceStatus: (status: ChildrenCell["status"]) => void;
};

type Config = {
    name: string;
    iconButton?: JSX.Element;
    callback?: () => void;
};

const GuideTemplateListHeader: FC<Props> = React.memo((props) => {
    const { closedStatus, replaceStatus } = props;

    const config = useMemo<Config[]>(() => {
        const started: Config[] = [
            {
                name: "Наименование",
                iconButton: React.createElement(
                    closedStatus ? ChevronDownIcon : ChevronUpIcon, {width: 18, height: 18}
                ),
                callback: () => replaceStatus(closedStatus),
            },
        ];
        return started.concat(layout.map((el) => ({ name: el })));
    }, [closedStatus, replaceStatus]);

    return (
        <div className={styles.list}>
            {config.map((el) => (
                <span
                    className={classNames(styles["list-item"], {
                        [styles.clickable]: el.callback,
                    })}
                    key={el.name}
                    onClick={el.callback}
                >
                    {el.name}
                    {el.iconButton && el.iconButton}
                </span>
            ))}
        </div>
    );
});

export default GuideTemplateListHeader;
