import { FC, forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { ConfigProvider, Form, Spin, Table } from "antd";
import {
    EditableColumnTypes,
    ColumnTypes,
    Data,
    DataSource,
} from "entities/EditableTable/Types";
import { EditableCell, EditableRow, HeaderTh } from "entities/EditableTable/UI";
import { IActionItem } from "entities/EditableTable/Types/actionCell";
import { useUpdateArray } from "hooks";
import styles from "./style.module.css";
import { TableEditContext } from "./context";

type Props = {
    data?: Data[] | null;
    columns: EditableColumnTypes;
    actions?: IActionItem[];
    isCreatingMode?: boolean;
    closeCreatingMode?: () => void;
    refetch?: () => Promise<void>; // refetch - слегка не однозначная сущность, вызывается после того, как произойдет мутация (редактирование, удаление), чтобы получить актуальные данные
};

const PER_PAGE = 10;

const components = {
    header: {
        cell: HeaderTh,
    },
    body: {
        cell: EditableCell,
        row: EditableRow,
    },
};

const EditableTable = forwardRef((props: Props, ref) => {
    const {
        data,
        columns,
        actions,
        refetch,
        isCreatingMode,
        closeCreatingMode,
    } = props;

    const ACTUAL_PER_PAGE = PER_PAGE + (isCreatingMode ? 1 : 0);

    const [editableDataItems, editableDataItemsActions] =
        useUpdateArray<DataSource>([], isCreatingMode, closeCreatingMode);

    const [pageNumber, setPageNumber] = useState(1);

    const dataSource = useMemo<DataSource[]>(() => {
        if (!data) return [];
        const parsed = data.map((el) => ({ ...el, key: el.Id }));
        if (!isCreatingMode) return parsed;
        return [
            { key: -1, Id: -1 },
            ...parsed.slice(
                (pageNumber - 1) * ACTUAL_PER_PAGE,
                pageNumber * ACTUAL_PER_PAGE
            ),
        ];
    }, [data, isCreatingMode, pageNumber]);

    function triggerPageEdit(id: number, key?: any, value?: any) {
        editableDataItemsActions.toggle(
            dataSource.slice(
                (pageNumber - 1) * ACTUAL_PER_PAGE,
                pageNumber * ACTUAL_PER_PAGE
            ),
            key,
            value
        );
    }

    const paginationOptions = useMemo(() => {
        if (isCreatingMode) return false;
        return {
            pageSize: ACTUAL_PER_PAGE,
            defaultPageSize: 10,
            hideOnSinglePage: true,
            onChange: (val: number) => {
                setPageNumber(val);
                editableDataItemsActions.reset();
            },
            showSizeChanger: false,
        };
    }, [editableDataItemsActions, setPageNumber, isCreatingMode]);

    const columns2 = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onHeaderCell: (data: any) => {
                return {
                    width: data.width,
                    onAddElem: data.onAddElem,
                    isCreatingMode,
                    isRequired: data.required,
                };
            },
            onCell: (record: DataSource) => {
                return {
                    record,
                    editable: col.editable,
                    refetch: refetch,
                    defaultValue: col.defaultValue,
                    editableDataItems: editableDataItems,
                    dataIndex: col.dataIndex,
                    editableDataItemsActions,
                    isRequired: col.required,
                    actions,
                    multiple: col.multiple,
                    options: col.options,
                    type: col.type,
                };
            },
        };
    });

    useImperativeHandle(
        ref,
        () => {
            return {
                changeField(id: number, key: string, value: any) {
                    editableDataItemsActions.update(id, key, value);
                },
            };
        },
        []
    );

    if (data === null || data === undefined) return <Spin size="large" />;
    if (!data.length) return <h1>Данные не найдены</h1>;

    return (
        <TableEditContext.Provider value={{ setAllPageEdit: triggerPageEdit }}>
            <ConfigProvider
                theme={{
                    components: {
                        Table: { cellPaddingBlock: 8, cellPaddingInline: 8 },
                    },
                }}
                renderEmpty={() => <></>}
            >
                <Table
                    components={components}
                    className={styles.table}
                    dataSource={dataSource}
                    tableLayout="fixed"
                    pagination={paginationOptions}
                    bordered
                    onRow={(record) => {
                        return {data: record, columns: columns2} as any
                    }}
                    columns={columns2 as ColumnTypes}
                />
            </ConfigProvider>
        </TableEditContext.Provider>
    );
});

export default EditableTable;