import { FC, useRef, useState, useEffect, useTransition } from "react";
import { TableBodyContext, TableContext } from "ExcelTable/context";
import {
    usePickedCells,
    useColumnPositions,
    useEditableCell,
} from "ExcelTable/helpers/hooks";
import ExcelHeader from "../ExcelHeader";
import ExcelRow from "../ExcelRow";
import styles from "./styles.module.css";
import { useEffectSkipFirstRender } from "hooks";
import { useSortedRows } from "ExcelTable/helpers/hooks/useSortedRows";
import { useMap } from "hooks/useMap";
import { ITableCell, RealTable } from "entities/ExcelTable/types";
import { useAppSelector } from "app/hooks";
import { CellActionModal } from "entities/ExcelTable/components";
import { DisabledSpace } from "components/UI";
import classNames from "classnames";

const regExp = /^[А-яA-z0-9]{1}$/;

type TableProps = {
    currentTable: RealTable;
    isLoading?: boolean;
};

const ExcelTable: FC<TableProps> = (props) => {
    const { currentTable, isLoading } = props;
    const { title, Id, Rows: data, Columns: columns } = currentTable;

    const ref = useRef(null);
    const [allOpen, setAllOpen] = useState(false);
    const [_, startTransition] = useTransition();
    const [allClose, setAllClose] = useState(false);
    const [activeCurrentCell, setActiveCurrentCell] =
        useState<ITableCell | null>(null);
    const { pickedCells, togglePickedCell, setOperation, setPickedCell } =
        usePickedCells();

    useEffectSkipFirstRender(() => {
        setAllClose((prev) => !prev);
    }, title);

    function onOpenRows() {
        startTransition(() => {
            setAllOpen((prev) => !prev);
        });
    }

    function changeActiveCurrentCell(cell?: ITableCell) {
        setActiveCurrentCell(cell ?? null);
    }

    const changeOperation = (event: KeyboardEvent) => {
        if (!(regExp.test(event.key) || event.key === "Backspace")) {
            return;
        }
        setOperation(event.key);
    };

    const cleanCells = () => {
        setPickedCell([]);
    };

    useEffect(() => {
        document.addEventListener("keyup", changeOperation);
        document.addEventListener("click", cleanCells);
        return () => {
            document.removeEventListener("keyup", changeOperation);
            document.removeEventListener("click", cleanCells);
        };
    }, []);

    const { currentColumn, sortedColumns, changeColumnsPositions } =
        useColumnPositions(Id, columns);

    const { actualEditable, updateEditable, increaseEditableCell } =
        useEditableCell(ref);

    const { rows, toggleSorting, sorted } = useSortedRows(data);

    const [hiddenCells, toggleHiddenCells] = useMap<string>();
    const { fontSize } = useAppSelector((state) => state.Excel);

    const [heightConfig, setHeightConfig] = useState({
        height: 0,
        isHidden: false,
    });

    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current) {
            const elem = containerRef.current as HTMLElement;

            setHeightConfig({
                ...heightConfig,
                height:
                    document.body.clientHeight -
                    elem.getBoundingClientRect().top -
                    8,
            });
        }
    }, [containerRef]);

    return (
        <DisabledSpace isActive={!!isLoading} zIndex={333}>
            <div
                ref={containerRef}
                style={{
                    maxHeight: heightConfig.height,
                }}
                // style={{
                //     height: heightConfig.isHidden
                //         ? "auto"
                //         : heightConfig.height,
                // }}
                className={styles["excel-table-container"]}
            >
                {/* <button
                    onClick={() => {
                        setHeightConfig({
                            ...heightConfig,
                            isHidden: !heightConfig.isHidden,
                        });
                    }}
                >
                    {heightConfig.isHidden ? "+" : "-"}
                </button> */}
                <table
                    className={styles["excel-table"]}
                    align="center"
                    cellPadding={0}
                    cellSpacing={0}
                    border={1}
                    ref={ref}
                    // onContextMenu={onPickCell}
                >
                    <TableContext.Provider
                        value={{
                            allClose,
                            allOpen,
                            changeColumnsPositions,
                            currentColumn,
                            hiddenCells,
                            toggleHiddenCells,
                            toggleSorting,
                            sorted,
                            changeActiveCurrentCell,
                        }}
                    >
                        <CellActionModal actionCell={activeCurrentCell} />
                        <ExcelHeader
                            columns={sortedColumns}
                            setAllClose={setAllClose}
                            onOpenRows={onOpenRows}
                        />
                        <TableBodyContext.Provider
                            value={{
                                actualEditable,
                                pickedCells,
                                setPickedCell,
                                updateEditable,
                                increaseEditableCell,
                            }}
                        >
                            <tbody
                                style={{ fontSize: fontSize + "px" }}
                                className={classNames({
                                    none: heightConfig.isHidden,
                                })}
                            >
                                {rows.map((row, id) => (
                                    <ExcelRow
                                        key={id}
                                        dataIndex={`row-${row.Id}`}
                                        item={row}
                                        columns={sortedColumns}
                                    />
                                ))}
                            </tbody>
                        </TableBodyContext.Provider>
                    </TableContext.Provider>
                </table>
                
            </div>
        </DisabledSpace>
    );
};

export default ExcelTable;
