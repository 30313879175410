import { Modal, Radio } from "antd";
import { createElement, FC, useMemo, useState } from "react";
import { getRealTable } from "entities/ExcelTable/api";
import { TableSearcher } from "entities/ExcelTable/components";
import { periodConfiguration } from "entities/ExcelTable/helpers/table.constants";
import { TablePageProps } from "entities/ExcelTable/types";
import { usePeriodFilter } from "ExcelTable/components/ExcelHeaderPanel/ExcelHeaderTitle/hooks/usePeriodFIlter";
import { HiddenContainer } from "ExcelTable/shared/components";
import ExcelTable from "ExcelTable/components/ExcelTable";
import { useEffectSkipFirstRender, useFetch } from "hooks";
import { BigLoader } from "components/UI";
import { getReportName2 } from "ExcelTable/helpers/methods";
import { useLocation } from "react-router-dom";
import { withExcelPage } from "entities/ExcelTable/hocs/withExcelPage";
import { DefaultModalFooter } from "shared/components";
import { useActions, useAppSelector } from "app/hooks";
import { TableBlock } from "widgets/ExcelTable";

const typeDictionary: Record<string, number> = {
    electric: 2,
    heat: 1,
};

const BalancePage: FC<TablePageProps> = (props) => {
    const location = useLocation();

    const { isEditingModalStatus } = useAppSelector((state) => state.Excel);
    const { changeEditingModalOpeningStatus } = useActions();

    const actualType = useMemo<number>(() => {
        return typeDictionary[location.pathname.split("/").at(-1) ?? ""] ?? 1;
    }, [location.pathname]);

    const { currentTableItem, updateNewItem, cancelModal, trigger } = props;

    const { filter, changeFilter, cleanFilters, settings, updateSettings } =
        usePeriodFilter(isEditingModalStatus !== false);

    const {
        data,
        trigger: refetch,
        isLoading,
    } = useFetch(getRealTable, {
        TypeId: actualType,
        variant: "balance",
    });

    useEffectSkipFirstRender(() => {
        refetch();
    }, trigger);

    const [currentVersion, setCurrentVersion] = useState<number | null>(null);

    const updateCurrentVersion = (currentVersion?: number) => {
        setCurrentVersion(currentVersion ?? null);
    };

    const onCancelModal = () => {
        cancelModal();
        cleanFilters();
    };

    return (
        <>
            <Modal
                width={1100}
                open={isEditingModalStatus !== false}
                title={`${
                    isEditingModalStatus === "edit"
                        ? "Редактировать"
                        : "Сформировать"
                } отчет по периоду`}
                onCancel={onCancelModal}
                onClose={onCancelModal}
                footer={() => (
                    <DefaultModalFooter
                        onAccept={() => {
                            updateNewItem({ mode: settings, ...filter });
                            changeEditingModalOpeningStatus(false);
                        }}
                        onReject={onCancelModal}
                    />
                )}
            >
                <Radio.Group
                    onChange={(e) => updateSettings(e.target.value)}
                    value={settings}
                    style={{
                        marginBottom: 8,
                    }}
                >
                    {periodConfiguration.map(({ name, code }) => (
                        <Radio.Button key={code} value={code}>
                            {name}
                        </Radio.Button>
                    ))}
                </Radio.Group>
                {periodConfiguration.map(({ code, Value }) => (
                    <HiddenContainer isHidden={settings !== code} key={code}>
                        {createElement(Value, {
                            filters: filter,
                            updateFilter: changeFilter,
                            isActive: settings === code,
                        })}
                    </HiddenContainer>
                ))}
            </Modal>

            
            <TableBlock
                currentTable={data}
                title={currentTableItem?.fullName ?? ""}
                isLoading={isLoading}
            />
            {/* {data ? (
                <ExcelTable
                    currentTable={{
                        ...data,
                        title: currentTableItem?.fullName ?? "",
                    }}
                    isLoading={isLoading}
                />
            ) : (
                <BigLoader />
            )} */}
        </>
    );
};

export default withExcelPage(
    BalancePage,
    "balance",
    getReportName2,
    (data: { [key: string]: string | number | null }) =>
        getReportName2(data, true),
    "Новый отчет",
    true,
    async (data: any) => {
        return {
            success: true,
            id: new Date().getTime(),
            tableId: new Date().getTime(),
        };
    }
);
