import { IRadioFilterItem } from "shared/types/is-energy";
import { CellHistoryTable } from "../components";
import {
    ActionTypes,
    ICellActionType,
    ResponseTable,
    TableVariants,
} from "../types";

export const menuConfig: { name: string; value: TableVariants }[] = [
    { name: "Эл.энергия", value: "Electric" },
    {
        name: "Сжатый воздух",
        value: "Compressed",
    },
    { name: "Тепло", value: "Heating" },
    { name: "Топливо", value: "Fuel" },
];

export const standarts: ResponseTable = {
    Id: 1,
    Size: 16,
    Columns: [
        {
            Id: 1,
            Name: "Наименование",
        },
        {
            Id: 4,
            Name: "Сырье                   [тонн]",
            Children: [
                {
                    Id: 2,
                    Name: "План",
                },
                {
                    Id: 3,
                    Name: "Факт",
                },
            ],
        },
        {
            Id: 7,
            Name: "Уд.  норма   [кВт-ч/т]",
            Children: [
                {
                    Id: 6,
                    Name: "Факт",
                },
                {
                    Id: 5,
                    Name: "План",
                },
            ],
        },
        {
            Id: 10,
            Name: "РАСХОД                               [кВт-ч]",
            Children: [
                {
                    Id: 8,
                    Name: "План",
                },
                {
                    Id: 9,
                    Name: "Факт",
                },
            ],
        },
        {
            Id: 13,
            Name: "ОТКЛОНЕНИЕ",
            Children: [
                {
                    Id: 11,
                    Name: "кВт-ч",
                },
                {
                    Id: 12,
                    Name: "%",
                },
            ],
        },
    ],
    Rows: [],
    EffectiveDate: "2024-10-16T07:50:40.627",
};

export const cellActionsDictionary: Record<ActionTypes, ICellActionType> = {
    history: {
        url: "/",
        Component: CellHistoryTable,
        title: "Показатели нормы",
        needDate: true,
    },
};

export const guideDictionary: Record<string, { full: string; short: string }> =
    {
        heating: { full: "Тепло", short: "Тепло" },
        electric: { full: "Электроэнергия", short: "Э.Э." },
        fuel: { full: "Топливо", short: "Топливо" },
        compressed: { full: "Сжатый воздух", short: "Сж.воздух" },
        CONST: { full: "Постоянные", short: "пост." },
        VARIABLE: { full: "Переменные", short: "пер." },
        HEAT: { full: "Тепло", short: "Тепло" },
        ELECTRIC_POWER: { full: "Электроэнергия", short: "Эл.энергия" },
        COMPRESSED_AIR: { full: "Сжатый воздух", short: "Сж.воздух" },
    };

export const iconDictionary: Record<string, JSX.Element> = {
    balance: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
        >
            <path
                d="M24.9163 43.333L35.4463 22.2513H28.1663V8.66634L17.333 29.748H24.9163V43.333ZM25.9997 4.33301C31.958 4.33301 37.0497 6.49967 41.2747 10.7247C45.4997 14.9497 47.6663 20.0413 47.6663 25.9997C47.6663 31.958 45.4997 37.0497 41.2747 41.2747C37.0497 45.4997 31.958 47.6663 25.9997 47.6663C20.0413 47.6663 14.9497 45.4997 10.7247 41.2747C6.49967 37.0497 4.33301 31.958 4.33301 25.9997C4.33301 20.0413 6.49967 14.9497 10.7247 10.7247C14.9497 6.49967 20.0413 4.33301 25.9997 4.33301Z"
                fill="#ED1A3B"
            />
        </svg>
    ),
    guides: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
        >
            <path
                d="M18.4381 4.33301H33.5615C34.0641 4.33301 34.4498 4.33301 34.7878 4.36551C37.1885 4.60167 39.1536 6.04467 39.9878 7.98817H12.0118C12.846 6.04467 14.8111 4.60167 17.2118 4.36551C17.5455 4.33301 17.9311 4.33301 18.4381 4.33301ZM13.6715 10.2328C10.6598 10.2328 8.18979 12.0528 7.36645 14.4665L7.31445 14.6182C8.17679 14.3582 9.07595 14.1848 9.98379 14.07C12.3238 13.7688 15.2835 13.7688 18.7198 13.7688H33.6525C37.0888 13.7688 40.0463 13.7688 42.3885 14.07C43.2985 14.187 44.1954 14.356 45.0578 14.6182L45.008 14.4665C44.1846 12.0528 41.7146 10.2328 38.7008 10.2328H13.6715Z"
                fill="#ED1A3B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7897 16.3408H33.2111C40.5214 16.3408 44.1787 16.3408 46.2327 18.4793C48.2867 20.6178 47.8014 23.9198 46.8351 30.526L45.9207 36.792C45.1624 41.9725 44.7832 44.5638 42.8397 46.1152C40.8962 47.6665 38.0297 47.6665 32.2946 47.6665H19.7062C13.9732 47.6665 11.1046 47.6665 9.16105 46.1152C7.21755 44.5638 6.83839 41.9725 6.08005 36.792L5.16572 30.5282C4.19722 23.9198 3.71405 20.6178 5.76805 18.4793C7.82205 16.3408 11.4794 16.3408 18.7897 16.3408ZM17.3337 38.9998C17.3337 38.1028 18.1419 37.3748 19.1386 37.3748H32.8622C33.8589 37.3748 34.6671 38.1028 34.6671 38.9998C34.6671 39.8968 33.8589 40.6248 32.8622 40.6248H19.1386C18.1419 40.6248 17.3337 39.8968 17.3337 38.9998Z"
                fill="#ED1A3B"
            />
        </svg>
    ),
};
