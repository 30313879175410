import React, { FC, InputHTMLAttributes } from "react";
import { ConfigProvider, Input } from "antd";
import { HurricaneInput } from "shared/components";

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
    onChange?: (value: string) => void;
};

const regExp = /^[+-]?([0-9]*[.])?[0-9]+$/;
const regExp2 = /[0-9,.-]/g;

const NumberInput: FC<Props> = (props) => {
    const { onChange, size: _, ...restProps } = props;

    // function inputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //     const value = e.target.value;
    //     if (value === "") return onChange?.("");
    //     if (!regExp.test(value)) return;
    //     onChange?.(value);
    //     // let parsedValue = e.target.value.replace(/(\d)-/g, "$1");
    //     // if (parsedValue === "-") parsedValue = "";
    //     // onChange && onChange(parsedValue);
    // }

    function onBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
        let value = e.target.value;
        value = value
            .replaceAll(/[\/\\\+А-яA-z\?\!\^=<>]/g, "")
            .replaceAll(/\D+$/g, "")
            .replaceAll(",", ".");
        onChange?.(value);
    }

    return (
        <ConfigProvider theme={{}}>
            <HurricaneInput
                onBlur={onBlur}
                onChange={(e) => onChange?.(e.target.value)}
                {...restProps}
            />
        </ConfigProvider>
    );
};

export default NumberInput;
