import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import "moment/locale/ru";
import "react-toastify/dist/ReactToastify.css";
import ru from "antd/locale/ru_RU";
import { ToastContainer } from "react-toastify";
import { BigLoader } from "./components/UI";

dayjs.locale("ru");

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Suspense fallback={<BigLoader />}>
                <ConfigProvider
                    locale={ru}
                    theme={{
                        token: {
                            fontSize: 16,
                            colorBorder: "#E4E4E7",
                        },
                        components: {
                            Input: {
                                colorBgContainer: "#f9f9f9",
                                algorithm: true, // Enable algorithm
                            },
                            Table: {
                                cellPaddingInline: 10,
                                cellPaddingBlock: 10,
                                algorithm: true,
                            },
                            Select: {
                                colorBgContainer: "#f9f9f9",
                                algorithm: true, // Enable algorithm
                            },
                            Radio: {
                                colorPrimary: "#ED1A3B",
                                algorithm: true, // Enable algorithm
                            },
                            Typography: {
                                fontSizeHeading1: 32,
                                fontSizeHeading2: 22,
                                fontSizeHeading3: 18,
                                fontSizeHeading4: 16,
                                fontSizeHeading5: 14,
                                margin: 0,
                            },
                        },
                    }}
                >
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnHover
                    />
                    <App />
                </ConfigProvider>
            </Suspense>
        </BrowserRouter>
    </Provider>
);
