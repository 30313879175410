import { IPointEvent } from "services/pointEvents/pointEventsSlice";

export const parseUniquePositions = (data: IPointEvent) => {
    const modifiers = data.ModifierData;

    return modifiers
        .filter(
            (value, index, self) =>
                self.findIndex(
                    (v) => v.CellMappingId === value.CellMappingId
                ) === index
        )
        .map((el) => el.Position)
        .join(", ");
};
