import { EditableColumnTypes } from "entities/EditableTable/Types";
import {
    ISpItem,
    ITablePosition,
    TableUsersPosition,
} from "entities/Positions/types";
import { parseUserName } from "entities/Users/api";
import { IUser, PositionUsersMap } from "entities/Users/types";
import { useMemo } from "react";

type ReturnType = [TableUsersPosition[], EditableColumnTypes];

export function usePositionForm(
    mappedUsers?: PositionUsersMap | null,
    data?: ITablePosition[] | null,
    users?: IUser[] | null,
    spItems?: ISpItem[] | null
): ReturnType {

    const dataWithUsers = useMemo<TableUsersPosition[]>(() => {
        if (!data?.length) return [];
        if (!mappedUsers) return data.map((el) => ({ ...el, users: [] }));
        return data.map((el) => {
            const item = mappedUsers[el.Id];
            if (!item) return { ...el, users: [] };
            return {
                ...el,
                users: item.map((el) => el.Id),
            };
        });
    }, [data, mappedUsers]);

    const columns = useMemo<EditableColumnTypes>(() => {
        if (!users || !spItems) return [];
        return [
            {
                title: "Объект",
                dataIndex: "SpId",
                editable: true,
                required: true,
                width: "25%",
                options: spItems.map((el) => ({ key: el.Name, value: el.Id })),
                render(_: any, record: any) {
                    return record.SupplyPointName;
                },
            },
            {
                title: "Установка",
                width: "32%",
                dataIndex: "Facility",
                editable: true,
                required: true,
            },
            {
                title: "Позиция",
                width: "10%",
                dataIndex: "Position",
                editable: true,
                required: true,
            },
            {
                title: "Пользователи",
                dataIndex: "users",
                width: "25%",
                editable: true,
                options: users.map((el) => ({
                    key: parseUserName(el),
                    value: el.Id,
                })),
                multiple: true,
                inLocalStorageStatus: "read",
                required: true,
                render(value: number[] | null) {
                    return (value ?? [])
                        .map((item) => {
                            const current = users?.find((el) => el.Id === item);
                            if (!current) return "Неизвестно";
                            return parseUserName(current);
                        })
                        .join(", ");
                },
            },
        ];
    }, [dataWithUsers, spItems, users]);

    return [dataWithUsers, columns];
}
