import React, { FC, ReactNode } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import RedButton from "shared/components/RedButton";

const AddButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement> & {icon?: ReactNode}> = (
    props
) => {
    const { children, icon, className, ...other } = props;
    return (
        <RedButton
            className={classNames(className, styles["add-button"])}
            {...other}
        >
            {icon ?? '+'} {children}
        </RedButton>
    );
};

export default AddButton;
