import { FC, useRef, useState } from "react";
import EditableElement from "../EditableElement";
import styles from "./styles.module.css";
import { useEffectSkipFirstRender } from "hooks";
import classNames from "classnames";

type Props = {
    value: string;
    fieldKey: string;
    style: React.CSSProperties
};
const BottomTableCell: FC<Props> = (props) => {
    const { fieldKey, value, style } = props;

    const ref = useRef(null);
    const [currentValue, setCurrentValue] = useState<string>(value ?? "");
    useEffectSkipFirstRender(() => {
        if (value !== currentValue) {
            setCurrentValue(value);
        }
    }, value);
    const [trigger, setTrigger] = useState<boolean>(false);

    return (
        <EditableElement
            value={currentValue}
            style={style}
            onChange={setCurrentValue}
            dataIndex={fieldKey + value + "труфанов"}
            ref={ref}
            onDoubleClick={() => setTrigger((prev) => !prev)}
            onEnterClick={() => setTrigger((prev) => !prev)}
            className={classNames(styles["bottom-table__value"], styles.cell)}
        />
    );
};

export default BottomTableCell;
