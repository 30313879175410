import { useState } from "react";
import { IPointEvent } from "services/pointEvents/pointEventsSlice";

type ReturnType = [
    cur: IPointEvent | null,
    changeCur: (cur?: IPointEvent) => void
];

export const useDeletingEvent = (): ReturnType => {
    const [currentEvent, setCurrentEvent] = useState<IPointEvent | null>(null);

    function changeEvent(cur?: IPointEvent) {
        setCurrentEvent(cur ?? null);
    }

    return [currentEvent, changeEvent];
};
