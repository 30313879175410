import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Crumb } from "entities/Breadcrumbs/components";
import { ICrumb } from "entities/Breadcrumbs/types";
import { crumbsDictionary } from "shared/constants/crumbsDictionary";
import styles from "./styles.module.css";
import { useActions, useAppSelector } from "app/hooks";
import { Button, ConfigProvider, Flex } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const Breadcrumbs = () => {
    const location = useLocation();

    const { activeIndex } = useAppSelector((state) => state.Excel);
    const { changeEditingModalOpeningStatus } = useActions();

    const crumbs = useMemo<ICrumb[]>(() => {
        const paths = location.pathname.split("/");
        if (paths.length < 3) return [];
        return paths.slice(1).map((el, id) => {
            const isActive = id === paths.length - 2;
            return {
                label:
                    (crumbsDictionary[el] ?? el) +
                    (isActive && activeIndex?.name
                        ? `: [${activeIndex.name}]`
                        : ""),
                url: paths.slice(0, id + 2).join("/"),
                isActive,
            };
        });
    }, [location.pathname, activeIndex]);

    return (
        <Flex gap={10} align="center">
            <div className={styles.breadcrumbs}>
                {crumbs.map((el) => (
                    <Crumb key={el.url} crumb={el} />
                ))}
            </div>

            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            primaryColor: "#e22739",
                            colorIconHover: "#e22739",
                            colorInfoTextHover: "#e22739",
                            defaultHoverColor: "#e22739",
                            defaultHoverBorderColor: "#e22739",
                            defaultActiveColor: "#e22739",
                            defaultActiveBorderColor: "#e22739",
                        },
                    },
                }}
            >
                {activeIndex?.name && (
                    <Button
                        className={styles["settings-icon"]}
                        onClick={() => changeEditingModalOpeningStatus('edit')}
                        icon={<SettingOutlined />}
                    />
                )}
            </ConfigProvider>
        </Flex>
    );
};

export default Breadcrumbs;
