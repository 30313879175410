import { Input, InputProps } from "antd";
import { FC } from "react";
import "./styles.scss";
import classNames from "classnames";

const HurricaneInput: FC<InputProps & { isLukoil?: boolean }> = (props) => {
    const { className, isLukoil, ...rest } = props;

    return (
        <Input
            className={classNames("hurricane-input", className, {
                lukoil: isLukoil,
            })}
            {...rest}
        />
    );
};

export default HurricaneInput;
