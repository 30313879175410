import { Flex, Radio, RadioChangeEvent } from "antd";
import { FC, useCallback, useState } from "react";
import { IPointEvent } from "services/pointEvents/pointEventsSlice";
import RadioVariant from "./components/RadioVariant";
import { usePointEventsContext } from "entities/PointEvents/context/PointEventsContext";
import RedButton from "shared/components/RedButton";
import {
    getDateInterval,
    parseDateFromBackToFront,
    PartialRecord,
} from "shared/helpers";
import PointItem from "./components/PointItem.tsx";
import { parseUniquePositions } from "entities/PointEvents/helpers";

type Props = {
    currentEvent: IPointEvent;
};

const variants = [
    {
        title: "Одобрить",
        description: "Выдать статус одобренного события",
        value: true,
    },
    // {
    //     title: "Отклонить",
    //     description: "Выдать статус заблокированного события",
    //     value: false,
    // },
];

const Dictionary: Partial<
    Record<
        keyof IPointEvent,
        string | { name: string; transform: (data: IPointEvent) => string }
    >
> = {
    BeginDate: {
        name: "Дата начала",
        transform: (data) => parseDateFromBackToFront(data.BeginDate, false),
    },
    SupplyPointName: "Объект",
    TypeLocalName: "Тип события",
    ModifierData: { name: "Ячейки", transform: parseUniquePositions },
};

const PeriodAccepting: FC<Props> = (props) => {
    const { currentEvent } = props;
    const [value, setValue] = useState<boolean | null>(null);

    const { onAcceptEvent } = usePointEventsContext();

    const changeValue = useCallback(
        (e: RadioChangeEvent) => {
            setValue(e.target.value);
        },
        [setValue]
    );

    return (
        <Flex vertical gap={12}>
            <Flex vertical gap={3}>
                {Object.entries(Dictionary).map(([key, value]) => (
                    <PointItem
                        title={typeof value === "string" ? value : value.name}
                        key={key}
                        value={
                            typeof value === "string"
                                ? String(currentEvent[key as keyof IPointEvent])
                                : value.transform(currentEvent)
                        }
                    />
                ))}
            </Flex>

            <Radio.Group
                value={value}
                onChange={changeValue}
                buttonStyle="outline"
            >
                {JSON.stringify(currentEvent)}
                <Flex vertical gap={32}>
                    <Flex vertical gap={12}>
                        {variants.map((el) => (
                            <RadioVariant key={el.title} {...el} />
                        ))}
                    </Flex>
                    <RedButton
                        onClick={(e) => onAcceptEvent(e, currentEvent.Id ?? 0)}
                        disabled={value === null}
                    >
                        Сохранить
                    </RedButton>
                </Flex>
            </Radio.Group>
        </Flex>
    );
};

export default PeriodAccepting;
