import { Space } from "antd";
import { FC, useMemo } from "react";
import { defaultConfig } from "entities/EditableTable/Constants/actionCell";
import { IActionItem } from "entities/EditableTable/Types/actionCell";
import { useEditableCellContext } from "entities/EditableTable/context/EditableCellContext";
import "./styles.scss";
import classNames from "classnames";
import { useEditableRowContext } from "entities/EditableTable/context/EditableRowContext";

type Props = {
    item: IActionItem;
    disabled: boolean;
    updateDisable: (value: boolean) => void;
};

const EditableActionButton: FC<Props> = (props) => {
    const { item, disabled, updateDisable } = props;

    const { changeEditing, activeItem, record, refetch } =
        useEditableCellContext();

    const isEditing = useMemo<boolean>(
        () => activeItem !== undefined,
        [activeItem]
    );

    function onSaveAction() {
        updateDisable(true);
        form.validateFields().then(() => {
            item.action(activeItem ?? record).finally(() => {
                updateDisable(false);
                changeEditing();
                refetch?.();
            });
        });
    }

    function onAction() {
        if (item.type !== "edit") {
            updateDisable(true);
            item.action(activeItem ?? record).finally(() => {
                updateDisable(false);
                refetch?.();
            });
        } else {
            changeEditing();
        }
    }

    const defaultElement = defaultConfig[item.type] ?? defaultConfig.simple;
    const { form } = useEditableRowContext();

    // если в режиме редактирования, и я не кнопка редактирования, то ничего не отрисовывать
    if (isEditing && item.type !== "edit") return null;
    // если в режиме редактирования, и я, логично, кнопка редактирования, то отрисовать кнопки сохранения и отмены
    if (isEditing)
        return (
            <Space
                size={8}
                wrap
                style={{ justifyContent: "center" }}
                align="center"
            >
                <button
                    onClick={onSaveAction}
                    disabled={disabled}
                    className={classNames("action-button", {
                        danger: defaultConfig.save.danger,
                        success: defaultConfig.save.success,
                    })}
                >
                    {defaultConfig.save.icon}
                </button>

                <button
                    onClick={() => {
                        changeEditing();
                        form.resetFields();
                    }}
                    disabled={disabled}
                    className={classNames("action-button", {
                        danger: defaultConfig.cancel.danger,
                        success: defaultConfig.cancel.success,
                    })}
                >
                    {defaultConfig.cancel.icon}
                </button>
            </Space>
        );
    // в противном случае просто отрисоваться по конфигу
    return (
        <button
            disabled={disabled}
            className={classNames("action-button", {
                danger: item?.danger ?? defaultElement.danger,
                success: item?.success ?? defaultElement.success,
            })}
            onClick={onAction}
        >
            {item.icon ?? defaultElement.icon}
        </button>
        // <Button
        //   type={'text'}
        //   icon={item.icon ?? defaultElement.icon}
        //   danger={item.danger ?? defaultElement.danger}
        //   onClick={onAction}
        //   disabled={disabled}
        // />
    );
};

export default EditableActionButton;
