import { Select, SelectProps } from "antd";
import classNames from "classnames";
import { FC } from "react";
import "./styles.scss";

type Options = {
    isLukoil?: boolean;
};

const HurricaneSelect: FC<
    Omit<SelectProps, "options"> &
        Options & { options?: { label: string; value: any }[] }
> = (props) => {
    const { isLukoil, className, notFoundContent, optionFilterProp, ...rest } =
        props;

    return (
        <Select
            {...rest}
            className={classNames(className, "hurricane-select", {
                lukoil: isLukoil,
            })}
            optionFilterProp={optionFilterProp || "label"}
            notFoundContent={notFoundContent || "Не найдено"}
        ></Select>
    );
};

export default HurricaneSelect;
