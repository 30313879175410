import { ITableColumn } from "entities/ExcelTable/types";
import { useLocalStorage } from "./useLocalStorage";
import { useMemo, useRef } from "react";

type ColumnPosition = {
    [key: string]: number;
};

function parseTableColumns(columns: ITableColumn[]): ColumnPosition {
    return columns.reduce<ColumnPosition>((acc, cur, id) => {
        return { ...acc, [cur.Id + cur.Name]: id };
    }, {});
}

export const useColumnPositions = (
    title: number,
    defaultColumns: ITableColumn[]
) => {
    const [columns, setColumns] = useLocalStorage<ColumnPosition>(
        `${title}-columns`,
        parseTableColumns(defaultColumns)
    );

    const sortedColumns = useMemo<ITableColumn[]>(() => {
        return [...defaultColumns].sort((a, b) => {
            return columns[a.Id + a.Name] - columns[b.Id + b.Name];
        });
    }, [defaultColumns, columns]);

    function changeColumnsPositions(target: HTMLTableCellElement) {
        const columnName = target.dataset.column;
        if (!columnName || columnName === currentColumn.current) return;
        setColumns((prev) => {
            const newColumns = { ...prev };
            newColumns[currentColumn.current] = prev[columnName];
            newColumns[columnName] = prev[currentColumn.current];
            return newColumns;
        });
    }

    const currentColumn = useRef<keyof ColumnPosition | "">("");

    return { columns, currentColumn, sortedColumns, changeColumnsPositions };
};
