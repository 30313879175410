import { Spin } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
    staticHeight?: number;
};
const BigLoader: FC<Props> = ({ staticHeight }) => {
    const ref = useRef<HTMLInputElement>(null);
    const [height, setHeight] = useState(staticHeight || 0);

    useEffect(() => {
        if (!ref.current || staticHeight) return;
        const rect = ref.current.getBoundingClientRect();
        setHeight(document.body.clientHeight - rect.top - 50);
    }, [ref, staticHeight]);

    return (
        <div ref={ref} className={styles.loader} style={{ height }}>
            <Spin
                indicator={
                    <LoadingOutlined
                        spin
                        style={{
                            fontSize: 48,
                            color: "#e22739",
                        }}
                    />
                }
                size="large"
            />
        </div>
    );
};

export default BigLoader;
