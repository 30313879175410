import React, { FC, useEffect, useMemo } from "react";
import { Input, Select, Checkbox, Form } from "antd";
import { DataSource } from "../../Types";
import EditableActionCell from "./EditableActionCell";
import { IActionItem, IOption } from "../../Types/actionCell";
import { EditableCellContext } from "../../context/EditableCellContext";
import { NumberInput } from "../../../../components/UI";
import { useTableEditContext } from "components/Widgets/EditableTable/context";
import './styles.scss';
import { useEffectSkipFirstRender } from "hooks";
import { useEditableRowContext } from "entities/EditableTable/context/EditableRowContext";

type Props = {
    record: DataSource;
    options?: IOption[];
    actions?: IActionItem[];
    editable: boolean;
    multiple?: boolean;
    children?: JSX.Element;
    dataIndex: string;
    defaultValue?: string | boolean | number | number[]
    isRequired?: boolean;
    placeholder?: string;
    editableDataItems: DataSource[];
    type?: string;
    refetch?: () => Promise<void>;
    editableDataItemsActions: {
        update: (key: number, name: keyof DataSource, value: any) => void;
        toggle: (elem: DataSource) => void;
    };
};

type MiniProps = Omit<
    Props,
    "children" | "editable" | "editableDataItems" | "refetch" | "actions"
> & { item: DataSource };

const EditableField: FC<MiniProps> = React.memo((props: MiniProps) => {
    const {
        record,
        dataIndex,
        editableDataItemsActions,
        placeholder,
        options,
        multiple,
        isRequired,
        defaultValue,
        item,
        type,
    } = props;

    useEffect(() => {
        if (options?.length && [null, undefined].includes(item[dataIndex])) {
            editableDataItemsActions.update(
                record.key,
                dataIndex,
                options[0].value
            );
        }
    }, []);

    const { setAllPageEdit } = useTableEditContext();
    const { form } = useEditableRowContext();

    useEffectSkipFirstRender(() => {
        if (options?.length) {
            form.setFieldValue(dataIndex, options[0].value)
        }
    }, options)

    function getField(field?: string): JSX.Element {
        switch (field) {
            case "select":
                return (
                    <Select
                        style={{ width: "100%" }}
                        mode={multiple ? "multiple" : undefined}
                        showSearch
                        className="editable-cell__select"
                        notFoundContent="Не найдено"
                        optionFilterProp="label"
                        defaultValue={options![0].value}
                        value={item[dataIndex]}
                        dropdownRender={(menu) => <div className="editable-cell__dropdown" style={{whiteSpace: 'normal'}}>{menu}</div>}
                        options={options?.map((el) => ({
                            label: el.key,
                            value: el.value,
                        }))}
                        onChange={(val) =>
                            editableDataItemsActions.update(
                                record.key,
                                dataIndex,
                                val
                            )
                        }
                        placeholder={placeholder ?? "Выберите..."}
                    />
                );
            case "checkbox":
                return (
                    <Checkbox
                        checked={item[dataIndex]}
                        defaultChecked={defaultValue === true}
                        onChange={(e) =>
                            editableDataItemsActions.update(
                                record.key,
                                dataIndex,
                                e.target.checked
                            )
                        }
                    />
                );
            case "number":
                return (
                    <NumberInput
                        value={item[dataIndex]}
                        placeholder={placeholder ?? "Введите..."}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                setAllPageEdit(
                                    record.key,
                                    dataIndex,
                                    (e.target as HTMLInputElement).value
                                );
                            }
                        }}
                        onChange={(value) =>
                            editableDataItemsActions.update(
                                record.key,
                                dataIndex,
                                value
                            )
                        }
                    />
                );
            default:
                return (
                    <Input
                        value={item[dataIndex]}
                        placeholder={placeholder ?? "Введите..."}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                setAllPageEdit(
                                    record.key,
                                    dataIndex,
                                    (e.target as HTMLInputElement).value
                                );
                            }
                        }}
                        onChange={(e) =>
                            editableDataItemsActions.update(
                                record.key,
                                dataIndex,
                                e.target.value
                            )
                        }
                    />
                );
        }
    }

    return (
        <td>
            <Form.Item
                name={dataIndex}
                key={dataIndex}
                required={isRequired}
                rules={
                    isRequired
                        ? [
                              {
                                  required: true,
                                  message: "Обязательное поле",
                              },
                          ]
                        : []
                }
            >
                {getField(options?.length ? "select" : type)}
            </Form.Item>
        </td>
    );
});

const EditableCell: FC<Props> = (props: Props) => {
    const {
        record,
        editable,
        children,
        dataIndex,
        refetch,
        actions,
        editableDataItemsActions,
        editableDataItems,
        ...other
    } = props;

    const activeItem = useMemo<DataSource | undefined>(() => {
        if (!editableDataItems) return undefined;
        return editableDataItems.find((el) => el.key === record.key);
    }, [record, editableDataItems]);

    // если колонка называется actions, то отрисовать ячейку с кнопками
    if (dataIndex === "actions")
        return (
            <EditableCellContext.Provider
                value={{
                    record,
                    refetch,
                    activeItem,
                    changeEditing: () =>
                        editableDataItemsActions.toggle(record),
                }}
            >
                <EditableActionCell actions={actions || []} />
            </EditableCellContext.Provider>
        );

    // если ячейка не имеет режима редактирования или сейчас не в режиме редактирования, то просто отрисовать содержимое
    if (!editable || activeItem === undefined)
        return (
            <td>
                <div>{children}</div>
            </td>
        );

    // если ячейка в режиме редактирования, то отрисовать поле для редаткирования(input / select)
    return (
        <EditableField
            {...other}
            dataIndex={dataIndex}
            record={record}
            item={activeItem}
            editableDataItemsActions={editableDataItemsActions}
        />
    );
};

export default EditableCell;
