import { FC, useEffect, useMemo } from "react";
import { IEventPosition } from "services/pointEvents/pointEventsSlice";
import { HurricaneSelect } from "shared/components";

type Props = {
    availablePositions: IEventPosition[];
    disabled?: boolean;
    value: number | null;
    updateCurrentPosition: (val: number) => void;
};

const SelectOnePosition: FC<Props> = (props) => {
    const { disabled, value, updateCurrentPosition, availablePositions } =
        props;

    useEffect(() => {
        if (availablePositions.length) {
            updateCurrentPosition(availablePositions[0].Id);
        }
    }, [availablePositions]);

    const parsed = useMemo(() => {
        return availablePositions.map((pos) => ({
            label: `${pos.Position} (${pos.MappedSupplyPointName ?? ""})`,
            value: pos.Id,
        }));
    }, [availablePositions]);
    return (
        <HurricaneSelect
            placeholder="Позиция:"
            onChange={updateCurrentPosition}
            style={{width: '100%'}}
            value={value ?? null}
            disabled={disabled}
            options={parsed}
        />
    );
};

export default SelectOnePosition;
