import { useEffect, useState } from "react";

function isInclude(key: number, home: number | number[]): boolean {
    if (Array.isArray(home)) {
        return home.includes(key);
    }
    return home === key;
}

export const useUpdateArray = <T extends { key: number }>(
    defaultData?: T[],
    isCreatingMode?: boolean,
    stopCreating?: () => void
): [
    T[],
    {
        update: (key: number, name: keyof T, value: T[keyof T]) => void;
        toggle: (elem: T | T[], key?: any, value?: any) => void;
        reset: () => void;
    }
] => {
    function updateElem(
        key: number | number[],
        name: keyof T,
        value: T[keyof T]
    ) {
        setData((prev) =>
            prev.map((el) =>
                isInclude(el.key, key) ? { ...el, [name]: value } : el
            )
        );
    }

    useEffect(() => {
        setData((prev) => {
            if (isCreatingMode) return [{ key: -1, Id: -1 }, ...prev] as T[];
            return prev.filter((el) => el.key !== -1);
        });
    }, [isCreatingMode]);

    function toggleElem(elem: T | T[], key?: any, value?: any) {
        if (Array.isArray(elem)) {
            setData((prev) => {
                let clone: T[] = [...prev];
                elem.forEach((el) => {
                    const elemItem = clone.find((item) => item.key === el.key);
                    if (elemItem) {
                        clone = clone.map((it) =>
                            it.key === elemItem.key
                                ? { ...it, [key]: value }
                                : it
                        );
                    } else {
                        const newElem = { ...el };
                        if (key && value) {
                            newElem[key as keyof T] = value;
                        }
                        clone.push(newElem);
                    }
                });
                return clone;
            });
        } else {
            const hasElem = data.find((el) => el.key === elem.key);
            setData((prev) => {
                if (hasElem) {
                    if (elem.key === -1) stopCreating?.();
                    return prev.filter((el) => el.key !== elem.key);
                }
                return [...prev, elem];
            });
        }
    }

    function cleanArray() {
        setData([]);
    }

    const [data, setData] = useState<T[]>(defaultData ?? []);

    return [
        data,
        { toggle: toggleElem, update: updateElem, reset: cleanArray },
    ];
};
