import {
    PeriodVariants,
    PeriodConfiguration,
    ITableCell,
    ITableRow,
    SortedState,
} from "entities/ExcelTable/types";
import { periods } from "../components/ExcelHeaderPanel/ExcelHeaderTitle/utils";
import { months } from "shared/constants/common";
import { guideDictionary } from "entities/ExcelTable/helpers/constants";

export const getLevelFunc = (children?: any[]) => {
    if (children === undefined) return 0;
    let depth = 0;
    for (let c = 0; c < children.length; c++) {
        let child = children[c];
        if (child.children != null && child.children.length > 0) {
            var tempDepth = getLevelFunc(child.children);
            if (tempDepth > depth) {
                depth = tempDepth;
            }
        }
    }
    return 1 + depth;
};

export function summaryDepth<T extends { children?: T[] }>(obj: T) {
    let counter = 0;
    if (!obj?.children?.length) return counter;
    counter = obj.children.length;
    obj.children.forEach((el) => {
        counter += summaryDepth(el);
    });
    return counter;
}

// export function hasSubString(
//     obj: { [key: string]: any },
//     substring: string
// ): boolean {
//     let isHasSubstring = false;

//     Object.values(obj).forEach((el) => {
//         if (el === null) return false;
//         if (typeof el === "object")
//             isHasSubstring = isHasSubstring || hasSubString(el, substring);
//         isHasSubstring =
//             isHasSubstring ||
//             `${el}`.toLowerCase().includes(substring.toLowerCase());
//     });

//     return isHasSubstring;
// }

export function hasSubstring<T extends { Cells: ITableCell[] }>(
    obj: T,
    substring: string
): boolean {
    return obj.Cells.some((el) =>
        String(el.CellData.Value)
            .toLowerCase()
            .includes(substring.toLowerCase())
    );
}

export function findLastMonthDay(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
}

const dateNumber = String(new Date().getDate());
const today = dateNumber.padStart(2, "0");

const dictionary: Record<PeriodVariants, { full: string; short: string }> = {
    Month: { full: "МЕCЯЦ", short: "мес." },
    Year: { full: "ГОД", short: "год" },
    Quartal: { full: "КВАРТАЛ", short: "квар." },
};

const monthDictionary: Record<string, string> = {
    oneWeek: "07",
    twoWeeks: "14",
    threeWeeks: "21",
};

const quartalDictionary: Record<
    string,
    { value: number; start: string; end: string }
> = {
    first: { value: 1, start: "01.01", end: "31.03" },
    second: { value: 2, start: "01.04", end: "30.06" },
    third: { value: 3, start: "01.07", end: "30.09" },
    fourth: { value: 4, start: "01.10", end: "31.12" },
};

const formMonth = (mode: PeriodVariants, value: number, isSubname = false) => {
    if (mode !== "Month") return "";
    if (isSubname) return months[value - 1].name.short + "-";
    const stringed = String(value);
    return (stringed.length === 1 ? `0${stringed}` : stringed) + " ";
};

const formQuartal = (mode: PeriodVariants, value: string) => {
    if (mode !== "Quartal") return "";
    const current = quartalDictionary[value] ?? undefined;
    if (!current) return "-";
    return `${current.value}-`;
};

const formiratePeriodInfo = (
    mode: PeriodVariants,
    period: string,
    year: number,
    month: number,
    date: string | null,
    isSubname = false
) => {
    if (mode === "Year")
        return isSubname ? "" : `[01.01.${year} - 31.12.${year}]`;
    if (mode === "Quartal")
        return isSubname
            ? ""
            : `[${quartalDictionary[period].start}.${year} - ${quartalDictionary[period].end}.${year}]`;
    const stringedMonth = String(month).padStart(2, "0");
    const periodOnRussian =
        periods.find((el) => el.value === period)?.name ?? "";
    let current =
        period === "untilDate"
            ? `${date}`
            : period === "untilToday"
            ? today
            : period === "allMonth"
            ? findLastMonthDay(month, year)
            : monthDictionary[period];
    if (period !== "untilDate" && !isSubname)
        current = `${current}.${stringedMonth}.${year}`;
    if (isSubname) return `01-${current}`;
    return `- ${periodOnRussian} - [01.${stringedMonth}.${year} - ${current}]`;
};

export const getReportName = (data: PeriodConfiguration | null) => {
    if (!data) return "";
    const {
        mode,
        filter: { month, period, year, date },
    } = data;
    return `${dictionary[mode]}: ${formMonth(mode, month)}${formQuartal(
        mode,
        period
    )}${year} ${formiratePeriodInfo(mode, period, year, month, date)}`;
};

export const getReportName2 = (
    data: {
        [key: string]: string | number | null;
    },
    isSubname = false
) => {
    if (!data) return "";

    const { mode, month, period, year, date } = data;

    const parsedMode = mode as PeriodVariants;
    const oldMonth = typeof month === "number" ? month : 0;
    const oldYear = typeof year === "number" ? year : new Date().getFullYear();
    const oldDate = typeof date === "string" ? date : "";

    return `${
        dictionary[parsedMode][isSubname ? "short" : "full"]
    }: ${formMonth(parsedMode, oldMonth, isSubname)}${formQuartal(
        parsedMode,
        String(period)
    )}${year} ${formiratePeriodInfo(
        parsedMode,
        String(period),
        oldYear,
        oldMonth,
        oldDate,
        isSubname
    )}`;
};

export const getGuideName = (
    data: {
        [key: string]: string | number | null;
    },
    isFullName = true
) => {
    const { energy, Mode, Period } = data;

    const parsedEnergy = String(energy);
    const parsedConstant = String(Mode);
    const mode = isFullName ? "full" : "short";
    const separatorText = isFullName ? "/ " : "";
    const endText = isFullName ? " нормы" : "";
    const parsedPeriod = String(Period).split("-")[0];

    return `${guideDictionary[parsedEnergy][mode]} ${parsedPeriod} ${separatorText}${guideDictionary[parsedConstant][mode]}${endText}`;
};

const findValue = (row: ITableRow, id: number): number => {
    const cell = row.Cells.find((el) => el.ColumnIds.includes(id));
    if (!cell?.CellData?.Value) return -Infinity;
    return +cell.CellData.Value;
};

export const sortRowsByField = (
    rows: ITableRow[],
    sorted: SortedState
): ITableRow[] => {
    if (!sorted.field) return rows;
    const cellId = +sorted.field.split("-")[1];
    return [...rows].sort((a, b) => {
        const aValue = findValue(a, cellId);
        const bValue = findValue(b, cellId);
        return sorted.mode !== "bottom" ? aValue - bValue : bValue - aValue;
    });
};
