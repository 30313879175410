import { Button, ConfigProvider, Flex } from "antd";
import styles from "./styles.module.scss";
import { FC } from "react";

type Props = {
    onAccept: () => void;
    onReject: () => void;
    acceptText?: string;
    rejectText?: string;
};
const DefaultModalFooter: FC<Props> = (props) => {
    const { onAccept, onReject, acceptText, rejectText } = props;

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        defaultBg: "whiter",
                        defaultActiveColor: "#ed1a3b",
                        defaultActiveBorderColor: "#ed1a3b",
                        defaultHoverBorderColor: '#ed1a3b',
                        defaultHoverColor: '#ed1a3b',
                        primaryColor: "white",
                        colorPrimary: "#ed1a3b",
                        colorPrimaryHover: "brown",
                    },
                },
            }}
        >
            <Flex
                align="center"
                justify="flex-end"
                gap={8}
                className={styles["footer"]}
            >
                <Button
                    onClick={onAccept}
                    className={styles["sub-button"]}
                    type="primary"
                >
                    {acceptText ?? "Принять"}
                </Button>
                <Button type="default" onClick={onReject} className={styles["sub-button"]}>
                    {rejectText ?? "Отклонить"}
                </Button>
            </Flex>
        </ConfigProvider>
    );
};

export default DefaultModalFooter;
