import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { loginSlice } from "../services/login/loginSlice";
import { pointEventsSlice } from "../services/pointEvents/pointEventsSlice";
import { cellMappingSlice } from "services/tableMappings/cellMappingSlice";
import { reducer as excelReducer } from "entities/ExcelTable/slice";
import { positionsApi } from "entities/Positions/api/rtk";

export const store = configureStore({
    reducer: {
        Login: loginSlice.reducer,
        PointEvents: pointEventsSlice.reducer,
        CellMapping: cellMappingSlice.reducer,
        Excel: excelReducer,
        [positionsApi.reducerPath]: positionsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 128 },
            serializableCheck: false,
        }).concat(positionsApi.middleware),
});

export const rtkHooks = {
    useGetSpQuery: positionsApi.useGetSpQuery,
    useGetSpMappingQuery: positionsApi.useGetSpMappingQuery,
    useGetUsersQuery: positionsApi.useGetUsersQuery,
    useSetUserMutation: positionsApi.useSetUserMutation,
    useDeleteUserMutation: positionsApi.useDeleteUserMutation,
    useEditPasswordMutation: positionsApi.useEditPasswordMutation,
    useEditUserMutation: positionsApi.useEditUserMutation,
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
