import { BigLoader } from "components/UI";
import { FC } from "react";
import { getRealTable } from "entities/ExcelTable/api";
import { TablePageProps } from "entities/ExcelTable/types";
import { useEffectSkipFirstRender, useFetch } from "hooks";
import ExcelTable from "ExcelTable/components/ExcelTable";
import { getGuideName } from "ExcelTable/helpers/methods";
import {
    GuideCreatingModal,
    GuideExistModal,
} from "entities/ISGuide/components";
import { withExcelPage } from "entities/ExcelTable/hocs/withExcelPage";
import { createRate, getRates } from "entities/ISGuide/api";
import { IRatesParams } from "entities/ISGuide/types";
import { useActions, useAppSelector } from "app/hooks";
import { useArchiveToReport } from "entities/ExcelTable/hooks/useArchiveToReport";
import { TableSearcher } from "entities/ExcelTable/components";

const GuidePage: FC<TablePageProps> = (props) => {
    const {
        currentTableItem,
        updateNewItem,
        createNewTab,
        errorId,
        cleanErrorId,
        trigger,
    } = props;

    const { activeIndex, isEditingModalStatus } = useAppSelector(
        (state) => state.Excel
    );
    const { changeActiveIndex, changeEditingModalOpeningStatus } = useActions();

    const {
        data,
        trigger: refetch,
        isLoading,
    } = useFetch(getRealTable, {
        TypeId: activeIndex?.table ?? 3,
        variant: "guide",
    });

    useEffectSkipFirstRender(() => {
        refetch();
    }, trigger);

    const generateReportName = useArchiveToReport();
    return (
        <>
            <GuideCreatingModal
                isOpen={isEditingModalStatus !== false}
                onCreateItem={updateNewItem}
                closeOpen={() => changeEditingModalOpeningStatus(false)}
            />

            <GuideExistModal
                id={errorId}
                closeModal={cleanErrorId}
                onAcceptModal={() => {
                    getRates()
                        .then((res) => {
                            const my = res.find((el) => el.Id === errorId);
                            changeActiveIndex(
                                errorId
                                    ? {
                                          table: my?.ReportTableId ?? 0,
                                          rate: errorId,
                                          name: generateReportName(my).fullName,
                                      }
                                    : null
                            );
                            return my
                                ? generateReportName(my)
                                : {
                                      name: `Архив ${errorId}`,
                                      fullName: `Архивный отчет ${errorId}`,
                                      filtersState: {},
                                      id: errorId ?? 77,
                                      tableId: 85,
                                  };
                        })
                        .then((data) => {
                            createNewTab(data);
                        })
                        .then(cleanErrorId);
                }}
            />

            {data ? (
                <ExcelTable
                    currentTable={{
                        ...data,
                        title: currentTableItem?.fullName ?? "",
                    }}
                    isLoading={isLoading}
                />
            ) : (
                <BigLoader />
            )}
        </>
    );
};

export default withExcelPage<IRatesParams>(
    GuidePage,
    "guide",
    getGuideName,
    (data: { [key: string]: string | number | null }) =>
        getGuideName(data, false),
    "Внести нормативы",
    false,
    createRate
);
