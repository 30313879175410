import { useState, useCallback } from "react";

type ReturnType = [boolean, () => void];

export const useTriggerModalCached = (): ReturnType => {
    const [showed, setShowed] = useState(false);
    const changeShowed = useCallback(() => {
        setShowed((prev) => !prev);
    }, [setShowed]);

    return [showed, changeShowed];
};
