import { Typography, Modal } from "antd";
import { FC, useMemo } from "react";
import { IPointEvent } from "services/pointEvents/pointEventsSlice";
import { DefaultModalFooter } from "shared/components";
import { parseDateFromBackToFront } from "shared/helpers";


type Props = {
    elem: IPointEvent | null;
    removeElem: () => void;
    onAccept: (id: number) => void;
};
const AcceptDeletingModal: FC<Props> = (props) => {
    const { elem, removeElem, onAccept } = props;

    const actualDate = useMemo<string>(() => {
        if (!elem?.BeginDate) return "";
        return parseDateFromBackToFront(elem.BeginDate);
    }, [elem?.BeginDate]);

    return (
        <Modal
            title="Подтвердите удаление"
            width={800}
            onClose={removeElem}
            onCancel={removeElem}
            open={!!elem}
            centered
            footer={() => <DefaultModalFooter onAccept={() => onAccept(elem?.Id ?? -1)} onReject={removeElem} />}
        >
            <Typography.Text strong>
                Вы действительно хотите удалить событие с типом{" "}
                "{elem?.TypeLocalName}" объекта "{elem?.SupplyPointName}" от{" "}
                {actualDate}
            </Typography.Text>
        </Modal>
    );
};

export default AcceptDeletingModal;
