import { useEffect, useMemo, useRef, useState } from "react";
import { getCurrentTabs } from "../api";
import { TablePageProps, TableTabItem, TabsParams } from "../types";
import { useActions, useAppSelector } from "app/hooks";
import { Flex, Modal } from "antd";
import { TableSearcher, TableTitle, TabsList } from "../components";
import { Archive } from "widgets/ExcelTable";
import { callSuccess } from "shared/helpers";
import { BottomTableContext } from "../context";

export const withExcelPage = <T extends object>(
    Component: React.ComponentType<TablePageProps>,
    type: TabsParams,
    renameFunction: (data: { [key: string]: string | number | null }) => string,
    setShortName: (data: { [key: string]: string | number | null }) => string,
    newTabText: string,
    canEditingTab: boolean,
    creatableFunction: (
        data: T
    ) => Promise<{ id: number; success: boolean; tableId: number }>
) => {
    return () => {
        const { titleGenerator, activeIndex, isEditingModalStatus, promises } =
            useAppSelector((state) => state.Excel);

        const isEditableMode = isEditingModalStatus === "edit";

        function removeTab(id: number) {
            setTabs((prev) => prev.filter((item) => item.id !== id));
        }

        const updateNewItem = (val: {
            [key: string]: string | number | null;
        }) => {
            if (isEditableMode) {
                modifiyTabs(val);
            } else {
                modifiyTabs(val);
            }
        };

        const createNewTab = (item: TableTabItem) => {
            if (tabs.some((tab) => tab.id === item.id)) {
                changeActiveIndex({
                    rate: item.id,
                    table: item.tableId,
                    name: item.fullName,
                });
                return;
            }
            setTabs((prev) => [...prev, item]);
        };

        async function modifiyTabs(newItemModel?: {
            [key: string]: string | number | null;
        }) {
            const obj = newItem ?? newItemModel;
            if (!obj) return;
            const newItemId = await creatableFunction(obj as T);
            // если сейчас есть newItem, значит, мы нажали на кнопку "Принять" при подтверждении. Этот процесс не обрабатываем. а просто заменяем табчик (написано на момент 05.12, бека для редактирования нет)
            if (!!newItem || isEditableMode) {
                setTabs((prev) =>
                    prev.map((el) =>
                        el.id === activeIndex?.rate
                            ? { ...el, filtersState: obj }
                            : el
                    )
                );
            } else {
                // в противном случае (который говорит о том, что мы создаем элемент) смотрим на результат запроса. На момент 05.12 ошибка могла возникнуть при попытке создать отчет по нормам, который уже есть в архиве
                if (newItemId.success) {
                    createNewTab({
                        id: newItemId.id,
                        tableId: newItemId.tableId,
                        fullName: titleGenerator(obj),
                        name: setShortName(obj),
                        filtersState: obj,
                    });
                    changeActiveIndex({
                        rate: newItemId.id,
                        table: newItemId.tableId,
                        name: `Новый отчет № ${newItemId.id}`,
                    });
                } else {
                    setErrorId(newItemId.id);
                }
            }
            if (newItemId.success || isEditableMode) {
                callSuccess(
                    `Отчет ${isEditableMode ? "изменен" : "сформирован"}`
                );
            }
            setNewItem(null);
        }

        const [tabs, setTabs] = useState<TableTabItem[]>([]);

        const [trigger, setTrigger] = useState<boolean>(false);
        const [newItem, setNewItem] = useState<{
            [key: string]: string | number | null;
        } | null>(null);

        const [errorId, setErrorId] = useState<number | null>(null);

        const {
            changeActiveIndex,
            regenerateTitle,
            clearEditings,
            changeShowHiddenValuesStatus,
            changeEditingModalOpeningStatus,
        } = useActions();

        const currentTableItem = useMemo<TableTabItem | undefined>(() => {
            return tabs.find((item) => item.id === activeIndex?.rate);
        }, [activeIndex, tabs]);

        useEffect(() => {
            getCurrentTabs({ type }).then((data) => {
                setTabs(data);
                changeActiveIndex(
                    data.length
                        ? {
                              rate: data[0].id,
                              table: data[0].tableId,
                              name: data[0].fullName,
                          }
                        : null
                );
            });
            regenerateTitle(renameFunction);
            return () => {
                changeActiveIndex(null);
                clearEditings();
                changeShowHiddenValuesStatus(false);
            };
        }, []);

        const [isEditingModalOpen, setIsEditingModalOpen] =
            useState<boolean>(false);

        const onCreateItem = () => {
            if (Object.keys(promises).length) {
                setIsEditingModalOpen(true);
            } else {
                changeEditingModalOpeningStatus("creating");
            }
        };
        const cancelModal = () => {
            changeEditingModalOpeningStatus(false);
            setNewItem(null);
        };

        const bottomTableRef = useRef<HTMLInputElement>(null);
        const [isBottomTableShowed, setIsBottomTableShowed] = useState(false);

        const toggleBottomShowed = () => {
            setIsBottomTableShowed((prev) => !prev);
            if (!isBottomTableShowed && bottomTableRef.current) {
                setTimeout(() => {
                    bottomTableRef.current!.scrollIntoView({behavior: 'smooth', block: 'end'});
                }, 200);
            }
        };

        return (
            <BottomTableContext.Provider
                value={{
                    ref: bottomTableRef,
                    isShowedBottom: isBottomTableShowed,
                }}
            >
                <Flex vertical gap={10}>
                    {/* <TableTitle
                onEditReport={() => {
                    changeEditingModalOpeningStatus("edit");
                }}
                tableTabItem={currentTableItem}
                canEditingTab={canEditingTab}
                setTrigger={setTrigger}
                onCancel={cancelModal}
                onAccept={() => {
                    if (newItem) modifiyTabs();
                }}
                newItem={newItem}
            /> */}
                    <Flex align="flex-start" justify="space-between" gap={12}>
                        <TabsList
                            items={tabs}
                            createTabTitle={newTabText}
                            onCreateItem={onCreateItem}
                            deleteItem={removeTab}
                        />

                        <Archive
                            type={type}
                            tabs={tabs}
                            // filters={{}}
                            createTab={createNewTab}
                        />
                    </Flex>

                    <Modal
                        open={isEditingModalOpen}
                        title="Внимание"
                        centered
                        width={800}
                        onCancel={() => setIsEditingModalOpen(false)}
                        onClose={() => setIsEditingModalOpen(false)}
                        footer={() => <></>}
                    >
                        <strong>
                            В ваших текущих отчетах имеются несохраненные
                            изменения. Примите их или сбросьте их перед тем, как
                            создать новый отчет
                        </strong>
                    </Modal>

                    <TableSearcher
                        isBottomShowed={isBottomTableShowed}
                        toggleBottomShowed={toggleBottomShowed}
                        setTrigger={setTrigger}
                    />

                    <Component
                        cancelModal={cancelModal}
                        createNewTab={createNewTab}
                        trigger={trigger}
                        errorId={errorId}
                        cleanErrorId={() => setErrorId(null)}
                        currentTableItem={currentTableItem}
                        updateNewItem={updateNewItem}
                    />
                </Flex>
            </BottomTableContext.Provider>
        );
    };
};
