import { getAuth } from "helpers/getAuth";
import { RestInstanse } from "../../../app/axiosInstance";
import { ISupplyPointCell } from "services/tableMappings/cellMappingSlice";

const BASE_URL = "/supply-point-cell";

export const createCell = async (data: ISupplyPointCell): Promise<ISupplyPointCell | null> => {
    try {
        const response = await RestInstanse.post<ISupplyPointCell>(
            BASE_URL,
            data,
            getAuth()
        );
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateCell = async (data: ISupplyPointCell) => {
    try {
        await RestInstanse.put<ISupplyPointCell>(BASE_URL, data, getAuth());
    } catch (error) {
        console.error(error);
    }
};

export const getCells = async () => {
    try {
        const response = await RestInstanse.get<ISupplyPointCell[]>(
            BASE_URL,
            getAuth()
        );
        // const result = response.data;

        // const ids = window.localStorage.getItem("TargetSupplyPointCellId");
        // if (ids) {
        //     const targetIds = ids.split(" ").map(Number);
        //     result.sort((a, b) => {
        //         return targetIds.indexOf(b.Id) - targetIds.indexOf(a.Id);
        //     });
        //     if (targetIds.length > 5) {
        //         window.localStorage.setItem(
        //             "TargetSupplyPointCellId",
        //             [...new Set(targetIds)].slice(-5).join(" ")
        //         );
        //     }
        // }

        return response.data.reverse();
    } catch (error) {
        console.error(error);
    }
};

export const deleteCell = async (data: ISupplyPointCell) => {
    try {
        await RestInstanse.delete(BASE_URL, {
            ...getAuth(),
            params: { id: data.Id },
        });
    } catch (error) {
        console.error(error);
    }
};
