import { BigLoader } from "components/UI";
import { BottomTable } from "entities/ExcelTable/components";
import { ResponseTable } from "entities/ExcelTable/types";
import ExcelTable from "ExcelTable/components/ExcelTable";
import { FC } from "react";

type Props = {
    currentTable: ResponseTable | null | undefined;
    title: string;
    isLoading: boolean;
};

const test = [
    {
        key: "ВСЕГО ПОТРЕБЛЕНО (ПНОС + АРЕНДА)",
        values: ["Папсназ", "25516651"],
    },
    { key: "Оплач. мощность (кВт)", values: "47528426" },
    {
        key: "Покупка э/энергии от ЛУКОЙЛ-ЭНЕРГОСЕРВИС",
        values: ["585778", "3838393"],
    },
    {
        key: "НЕ БАЛАНС",
        values: ["2422424", "8888888", "137564642", "57557754"],
    },
];

const TableBlock: FC<Props> = (props) => {
    const { currentTable, isLoading, title } = props;

    if (!currentTable) return <BigLoader />;
    return (
        <div>
            <ExcelTable
                currentTable={{
                    ...currentTable,
                    title,
                }}
                isLoading={isLoading}
            />
            <BottomTable items={test} />
        </div>
    );
};

export default TableBlock;
