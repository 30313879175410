import { Input, InputProps } from "antd";
import { FC } from "react";
import "./styles.css";
import classNames from "classnames";
import { SearchIcon } from "lucide-react";

const SearchInput: FC<
    InputProps & { compacted?: boolean; isLukoil?: boolean }
> = (props) => {
    const { className, compacted, isLukoil, ...rest } = props;

    return (
        <div
            className={classNames("search-input", {
                compacted,
                lukoil: isLukoil,
            })}
        >
            <SearchIcon className={classNames("search-icon", "search-left")} />
            <Input
                allowClear
                className={classNames("search-item", className)}
                {...rest}
            />
        </div>
    );
};

export default SearchInput;
