import { createContext, useContext } from "react";

interface IBottomTableContext {
    ref: React.LegacyRef<HTMLDivElement>;
    isShowedBottom: boolean;
}

export const BottomTableContext = createContext<IBottomTableContext | undefined>(
    undefined
);

export const useBottomTableContext = () => {
    const context = useContext(BottomTableContext);
    if (!context) throw new Error("context error");
    return context;
};
