import {
    createAuthorityArguments,
    IAuthority,
    IUser,
    PositionUsersMap,
} from "../types";
import { RestInstanse } from "../../../app/axiosInstance";
import { getAuth } from "helpers/getAuth";


// .map((el) => ({
//     key: `${[el.LastName, el.FirstName].join(" ")}`,
//     value: el.Id,
// }))

export function parseUserName(user: IUser) {
    return `${user.LastName} ${user.FirstName}${user.Login ? ` (${user.Login})` : ''}`;
}



export const getUserPositions = async () => {
    try {
        const response = await RestInstanse.get<PositionUsersMap>(
            "/user-supply-point-position-mapping/position-to-added-user",
            getAuth()
        );
        return response.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getAuthorities = async () => {
    try {
        const response = await RestInstanse.get<{ Authorities: IAuthority[] }>(
            "/authorities/all",
            getAuth()
        );
        return response.data.Authorities;
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const createAuthority = async (
    body: createAuthorityArguments
): Promise<boolean> => {
    const { objects, UserLogin, Role } = body;

    try {
        const promises = objects.map((el) =>
            RestInstanse.post(
                "/authorities",
                { UserLogin, Role, SupplyPointId: el },
                getAuth()
            )
        );
        await Promise.all(promises);
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};
