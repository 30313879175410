import { FC, createElement, useMemo } from "react";
import styles from "./style.module.css";
import classNames from "classnames";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useActions, useAppSelector } from "app/hooks";
import { TabsItem } from "entities/ExcelTable/types";

type Props = {
    onCreateItem?: () => void;
    openModal: (val: number) => void;
    item: TabsItem;
    isEditingMode?: boolean;
};

const ExcelTab: FC<Props> = (props) => {
    const {
        item: { name, id, tableId, fullName },
        onCreateItem,
        openModal,
        isEditingMode,
    } = props;

    const { activeIndex, promises } = useAppSelector((state) => state.Excel);
    const { changeActiveIndex } = useActions();

    const isActive = id === activeIndex?.rate;
    const isNewItem = onCreateItem !== undefined;

    const isInPromises = useMemo<boolean>(
        () => Object.keys(promises).includes(String(id)),
        [promises, id]
    );

    return (
        <div
            onClick={() => {
                if (isNewItem) {
                    onCreateItem();
                } else {
                    changeActiveIndex({ rate: id, table: tableId, name: fullName });
                }
            }}
            className={classNames(styles["tab"], {
                [styles.active]: isActive,
                [styles.new]: isNewItem,
                [styles.editing]: isInPromises,
                [styles.editMode]: isEditingMode,
            })}
        >
            <span>{name}</span>
            {createElement(isNewItem ? PlusOutlined : CloseOutlined, {
                className: styles["tab__icon"],
                onClick: (e) => {
                    e.stopPropagation();
                    if (isNewItem) {
                        onCreateItem();
                    } else {
                        openModal(id);
                    }
                },
            })}
        </div>
    );
};

export default ExcelTab;
