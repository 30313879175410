import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import "./styles.scss";

type Props = {
    width: string;
    onAddElem?: () => void;
    isCreatingMode?: boolean;
    isRequired?: boolean;
};

const HeaderTh: FC<PropsWithChildren<Props>> = (props) => {
    const { children, width, onAddElem, isRequired, isCreatingMode } = props;

    const isShowedPlus = isCreatingMode && onAddElem;
    return (
        <th
            style={{ width, padding: 8 }}
            className={classNames("ant-table-cell editable-table__th")}
        >
            <div
                className={classNames("header-title", {
                    required: isRequired && isCreatingMode,
                })}
            >
                {children}
                {isShowedPlus && <button onClick={onAddElem}>+</button>}
            </div>
        </th>
    );
};

export default HeaderTh;
