import { FC, useEffect, useMemo, useState } from "react";
import { Button, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";
import classNames from "classnames";
import GuideTemplateResource from "../GuideTemplateResource";
import {
    ITemplate,
    Template,
    TemplateMap,
    IResource,
    ChildrenCell,
} from "../../types";
import { useEffectSkipFirstRender } from "hooks";

type Props = {
    template: ITemplate;
    onClick: (template: Template) => void;
    order?: number;
    toggleChildren?: (
        key: ChildrenCell["key"],
        value: ChildrenCell["status"]
    ) => void;
    globalOpenStatus: {
        status: ChildrenCell["status"];
        trigger: boolean;
    };
    dataFilter: string;
};

type HiddenStatus = "hidden" | "onlyself" | "children";

function deepFindTemplate(template: ITemplate, data: string): HiddenStatus {
    let status: HiddenStatus = "hidden";

    if (!data || template.Position.toLowerCase().includes(data.toLowerCase())) {
        status = "onlyself";
    }
    if (!template.Children) {
        return status;
    }
    for (let i = 0; i < template.Children.length; i++) {
        if (deepFindTemplate(template.Children[i], data) !== "hidden") {
            status = "children";
            break;
        }
    }
    return status;
}

const GuideTemplateListItem: FC<Props> = (props) => {
    const {
        template,
        onClick,
        order,
        toggleChildren,
        dataFilter,
        globalOpenStatus,
    } = props;
    const { Position, Children, EffectiveDate, PositionId } = template;

    const hiddenStatus = useMemo<HiddenStatus>(() => {
        return deepFindTemplate(template, dataFilter);
    }, [dataFilter, template]);

    const current = useMemo<Template>(() => {
        const parsedMap = template.Map.reduce<TemplateMap>(
            (acc: TemplateMap, cur: IResource) => {
                const { AxisName, EnergySourceId, Mode } = cur;
                if (acc[AxisName]) {
                    return {
                        ...acc,
                        [AxisName]: {
                            ...acc[AxisName],
                            [String(EnergySourceId)]: Mode,
                        },
                    };
                }
                return {
                    ...acc,
                    [AxisName]: { [String(EnergySourceId)]: Mode },
                };
            },
            {}
        );
        const newItem: Template = { ...template, Map: parsedMap };
        return newItem;
    }, [template]);

    const [isOpenChildren, setIsOpenChildren] = useState<boolean>(false);

    useEffectSkipFirstRender(() => {
        setIsOpenChildren(globalOpenStatus.status);
    }, globalOpenStatus);

    const onOpenChildren = () => {
        if (Children?.length) {
            setIsOpenChildren((prev) => !prev);
            toggleChildren?.(PositionId, !isOpenChildren);
        }
    };

    useEffect(() => {
        if (Children?.length) {
            const isTrue = dataFilter.length > 0;
            setIsOpenChildren(isTrue);
            toggleChildren?.(PositionId, isTrue);
        }
    }, [dataFilter]);

    const year = useMemo<string>(() => {
        if (!EffectiveDate) return "—";
        return EffectiveDate.split("-")[0];
    }, [EffectiveDate]);

    const sortedMap = useMemo(() => {
        const clone = [...template.Map];
        return clone.sort((a, b) =>
            a.EnergySourceName.localeCompare(b.EnergySourceName)
        );
    }, [template.Map]);

    return (
        <>
            <div
                className={classNames(styles.template, {
                    [styles.hidden]: hiddenStatus === "hidden",
                })}
            >
                <button
                    onClick={onOpenChildren}
                    className={classNames(styles["template__title"], {
                        [styles.open]: isOpenChildren,
                        [styles.alone]: hiddenStatus !== "children",
                    })}
                >
                    <span
                        className={classNames(styles["template__name"], {
                            [styles.error]: false,
                        })}
                        style={{ paddingLeft: (order ?? 0) * 12 }}
                    >
                        {Position}
                    </span>
                </button>
                <span
                    className={classNames(styles["template__year"], {
                        [styles.error]: !EffectiveDate,
                    })}
                >
                    {year}
                </span>
                <div
                    className={classNames(
                        "light-scroll",
                        styles["resource-list"]
                    )}
                >
                    {sortedMap.map((el) => (
                        <GuideTemplateResource
                            key={el.EnergySourceId}
                            name={el.EnergySourceName}
                            isError={el.Mode === "VARIABLE"}
                        />
                    ))}
                </div>
                <Tooltip title="Редактировать">
                    <Button
                        onClick={() => onClick(current)}
                        icon={<EditOutlined />}
                    />
                </Tooltip>
            </div>
            {Children?.length &&
                isOpenChildren &&
                hiddenStatus !== "hidden" &&
                Children.map((el, id) => (
                    <GuideTemplateListItem
                        template={el}
                        dataFilter={dataFilter}
                        globalOpenStatus={globalOpenStatus}
                        key={`${order ?? 0}-${id}`}
                        onClick={onClick}
                        order={(order ?? 0) + 1}
                    />
                ))}
        </>
    );
};

export default GuideTemplateListItem;
