import { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useTableBodyContext, useTableContext } from "../context";
import classNames from "classnames";
import { useActions, useAppSelector } from "app/hooks";
import { EditableCellProps } from "entities/ExcelTable/types";

import { useEffectSkipFirstRender } from "hooks";
import { EditableElement } from "entities/ExcelTable/components";

interface EditableRef {
    setEditCell: () => void;
    close: () => void;
    openStatus: boolean;
}

const ExcelColumnItem: FC<EditableCellProps> = (props) => {
    const editableRef = useRef<EditableRef>(null);

    function onEnterClick() {
        const adaptedValue =
            value?.CellData?.CellType === "NUMERIC"
                ? inputValue
                      .replaceAll(".", ",")
                      .replaceAll(/[А-яA-z\/\+\=\\]/g, "")
                      .replaceAll(/\D+$/g, "")
                : inputValue;
        if (backup.current !== adaptedValue && value?.Id) {
            addEditing([String(value.Id), adaptedValue]);
            backup.current = adaptedValue;
            setInputValue(adaptedValue);
        }
        increaseEditableCell();
    }

    const { dataIndex, value, style, canHidden, blocked } = props;

    const valueInfo = useMemo(() => {
        return {
            stringValue: value ? String(value.CellData.Value ?? "") : "",
            notAllowedToEdit: blocked || value?.clickable,
        };
    }, [value, blocked]);

    const [inputValue, setInputValue] = useState<string>(valueInfo.stringValue);
    const backup = useRef<string>(valueInfo.stringValue);

    const [_, setTrigger] = useState(false);

    const { updateEditable, actualEditable, increaseEditableCell } =
        useTableBodyContext();

    const { hiddenCells } = useTableContext();

    const { promises, activeIndex } = useAppSelector((state) => state.Excel);

    const { addEditing } = useActions();

    useEffect(() => {
        if (actualEditable === dataIndex) {
            editableRef.current?.setEditCell();
        } else {
            editableRef.current?.close();
            setInputValue(backup.current);
        }
        setTrigger((prev) => !prev);
    }, [actualEditable]);

    useEffect(() => {
        const cur =
            promises[String(activeIndex)]?.[dataIndex] ?? valueInfo.stringValue;
        backup.current = cur;
        setInputValue(cur);
    }, [dataIndex, valueInfo.stringValue]);

    const isEdited = useMemo<boolean>(() => {
        if (!value?.Id) return false;
        const current = promises[String(activeIndex?.rate)]?.[String(value.Id)];
        return !!current;
    }, [promises, value]);

    useEffectSkipFirstRender(() => {
        if (!isEdited) {
            backup.current = valueInfo.stringValue;
            setInputValue(valueInfo.stringValue);
        }
    }, isEdited);

    const isNone = useMemo<boolean>(() => {
        return !!canHidden && hiddenCells.some((el) => dataIndex.includes(el));
    }, [canHidden, hiddenCells]);

    return (
        <EditableElement
            style={style}
            value={inputValue}
            ref={editableRef}
            onChange={setInputValue}
            isNotAllowedToEdit={valueInfo.notAllowedToEdit || !value?.Id}
            dataIndex={dataIndex}
            className={classNames(styles["excel-column-item"], {
                [styles.active]: editableRef.current?.openStatus,
                [styles.edited]: isEdited && !editableRef.current?.openStatus,
                [styles.none]: isNone,
            })}
            onEnterClick={onEnterClick}
            onDoubleClick={() => updateEditable(dataIndex)}
        />
    );
};

export default ExcelColumnItem;
