import { toast } from "react-toastify";

export type ToNull<T extends object> = { [U in keyof T]: T[U] | null };

export type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};
export const callError = (text: string) =>
    toast.error(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });

export const callSuccess = (text: string) => {
    toast.success(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
    });
};

export const parseObjectToString = (obj: {
    [key: string]: string | number | null;
}): string => {
    return Object.entries(obj).reduce(
        (acc: string, cur: [string, string | number | null]) => {
            const [key, val] = cur;
            return `${acc}&${key}=${val}`;
        },
        ""
    );
};

export const getCurrentDateInString = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

export const setDeep = (value: string, key: string, id: string, obj: any) => {
    if (!obj[id]) {
        return { ...obj, [id]: { [key]: value } };
    }
    return { ...obj, [id]: { ...obj[id], [key]: value } };
};

export function parseJson<T>(jsonString: string): T {
    return JSON.parse(jsonString) as T;
}

export const parseDateFromBackToFront = (date: string, needTime?: boolean, prefix?: string) => {
    const [day, time] = date.split("T");
    const needableTime = time.split(":").slice(0, 2).join(":");
    const currentDate = day.split("-").reverse().join(".");
    if (!needTime) return currentDate;
    return `${currentDate}${prefix ?? " "}${needableTime}`;
};

export const getDateInterval = (
    dates: [string, string],
    startPrefix?: string,
    endPrefix: string = "-"
): string => {
    const [start, end] = dates.map((el) => parseDateFromBackToFront(el));

    return `${
        startPrefix ? startPrefix + " " : ""
    }${start} ${endPrefix} ${end}`;
};

export const setNewDate = (oldDate: string, newDate: string) => {
    const newParsedDate = newDate.split('.').reverse().join('-')
    const time = oldDate.split('T').at(-1);
    return [newParsedDate, time].join('T')
}