import classNames from "classnames";
import React, { FC, PropsWithChildren, useId } from "react";
import styles from "./styles.module.scss";

const HurricaneCheckbox: FC<
    PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>>
> = (props) => {
    const { className, children, style, ...rest } = props;
    const id = useId();

    return (
        <>
            <input
                className={styles["hurricane__checkbox"]}
                {...rest}
                type="checkbox"
                id={id}
            />{" "}
            <label
                htmlFor={id}
                style={style}
                className={classNames(className, styles["hurricane__label"])}
            >
                {children}
            </label>
        </>
    );
};

export default HurricaneCheckbox;
