import { createContext, useContext } from "react";

import { FormInstance } from "antd";

interface IEditableRowContext {
    form: FormInstance<any>;
}

export const EditableRowContext = createContext<
    IEditableRowContext | undefined
>(undefined);

export const useEditableRowContext = () => {
    const context = useContext(EditableRowContext);
    if (!context) throw new Error("context error");
    return context;
};
