import { FC } from "react";
import BottomTableRow from "./BottomTableRow";
import styles from "./styles.module.css";
import { useAppSelector } from "app/hooks";
import { useBottomTableContext } from "entities/ExcelTable/context";
import classNames from "classnames";

type Props = {
    items: { key: string; values: string[] | string }[];
};
const BottomTable: FC<Props> = ({ items }) => {
    const {
        colors: { bottom },
    } = useAppSelector((state) => state.Excel);
    const { ref, isShowedBottom } = useBottomTableContext();

    return (
        <div
            ref={ref}
            style={{
                display: isShowedBottom ? "block" : "none",
                paddingBottom: 8,
                marginLeft: 18,
                left: 0,
                position: 'sticky'
            }}
        >
            <div
                style={{
                    background: bottom,
                }}
                className={classNames(styles["bottom-table"])}
            >
                {items.map((el, index) => (
                    <BottomTableRow key={index} item={el} />
                ))}
            </div>
        </div>
    );
};

export default BottomTable;
