import { Button, Checkbox, Flex, Form, InputNumber } from "antd";
import { FormInstance } from "antd/es/form";
import { EditableColumnTypes } from "entities/EditableTable/Types";
import { FC, useEffect, useState } from "react";
import HurricaneSelect from "../HurricaneSelect";
import HurricaneInput from "../HurricaneInput";

type Props = {
    initialValue?: Record<string, any>;
    form: FormInstance<any>;
    trigger?: boolean;
    fields: EditableColumnTypes;
};
const CreatableForm: FC<Props> = (props) => {
    const { initialValue, form, fields, trigger } = props;

    function updateField(key: string, value: any) {
        form.setFieldValue(key, value);
    }

    useEffect(() => {
        const initial = fields.reduce<Record<string, any>>(
            (acc: Record<string, any>, cur: EditableColumnTypes[0]) => {
                if (cur.defaultValue !== undefined) {
                    acc[cur.dataIndex] = cur.defaultValue;
                }

                return acc;
            },
            {}
        );
        if (Object.keys(initial).length) {
            form.setFieldsValue(initial);
        }
    }, [fields, trigger]);

    return (
        <Form
            initialValues={initialValue}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 48 }}
            form={form}
            validateTrigger="onSubmit"
        >
            {fields.map((field) => (
                <Form.Item
                    key={field.dataIndex}
                    name={field.dataIndex}
                    label={
                        <FormCreateTitle
                            text={field.title as string}
                            dataIndex={field.dataIndex}
                            isInLocalStorage={
                                field.inLocalStorageStatus === "read"
                            }
                            onAddElem={field.onAddElem}
                            updateField={updateField}
                        />
                    }
                    valuePropName={
                        field?.type === "checkbox" ? "checked" : "value"
                    }
                    required={field.required}
                    rules={
                        field.required
                            ? [
                                  {
                                      required: true,
                                      message: "Обязательное поле",
                                  },
                              ]
                            : []
                    }
                >
                    {field.options ? (
                        <HurricaneSelect
                            showSearch
                            mode={field.multiple ? "multiple" : undefined}
                            allowClear
                            options={(field.options || []).map((option) => ({
                                label: option.key,
                                value: option.value,
                            }))}
                            placeholder={field.title as string}
                        />
                    ) : field?.type === "number" ? (
                        <InputNumber
                            placeholder={field.title as string}
                            min={0}
                        />
                    ) : field?.type === "checkbox" ? (
                        <Checkbox />
                    ) : (
                        <HurricaneInput
                            onBlur={() => field.onBlur?.(form)}
                            placeholder={field.title as string}
                        />
                    )}
                    {/* <FieldFabric field={field} /> */}
                </Form.Item>
            ))}
        </Form>
    );
};

export default CreatableForm;

type TitleProps = {
    text: string;
    dataIndex: string;
    isInLocalStorage?: boolean;
    updateField: (key: string, value: any | any[]) => void;
    onAddElem?: () => void;
};

const FormCreateTitle: FC<TitleProps> = (props) => {
    const { text, dataIndex, isInLocalStorage, updateField, onAddElem } = props;
    const [item, setItem] = useState(() => {
        if (!isInLocalStorage) return null;
        const value = localStorage.getItem(dataIndex);
        if (!value) return null;
        return JSON.parse(value);
    });

    const getFieldFromLocalStorage = () => {
        updateField(dataIndex, item);
    };

    return (
        <Flex align="center" gap={8}>
            <span>{text}</span>
            <Flex gap={5}>
                {isInLocalStorage && item && (
                    <Button onClick={getFieldFromLocalStorage}>
                        Последнее
                    </Button>
                )}
                {onAddElem && <Button onClick={onAddElem}>Добавить</Button>}
            </Flex>
        </Flex>
    );
};
